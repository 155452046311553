import {
    FETCH_ELEICOES,
    FETCH_ELEICOES_LIBERADAS,
    FETCH_ELEICAO_CANDIDATOS,
    CREATE_ELEICAO,
    EDIT_ELEICAO,
    FINALIZA_ELEICAO,
    GET_ELEICAO,
    GET_ELEICAO_SIMULADA,
    VOTAR_ELEICAO,
} from "../actions/types";

const INITIAL_STATE = {
    originais: [],
    simuladas: [],
    eleicao: null,
    eleicaoSimulada: null,
    candidato: [],
    filter: false,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_ELEICOES:
            return {
                ...state,
                originais: action.payload.data.originais,
                simuladas: action.payload.data.simuladas,
                filter: true,
            };
        case FETCH_ELEICOES_LIBERADAS:
            return {
                ...state,
                originais: action.payload.data.originais,
                simuladas: action.payload.data.simuladas,
                filter: false,
            };
        case FETCH_ELEICAO_CANDIDATOS:
            return { ...state };
        case CREATE_ELEICAO:
            return { ...state };
        case EDIT_ELEICAO:
            return { ...state };
        case FINALIZA_ELEICAO:
            return { ...state };
        case VOTAR_ELEICAO:
            return { ...state };
        case GET_ELEICAO:
            return { ...state, eleicao: action.payload };
        case GET_ELEICAO_SIMULADA:
            return { ...state, eleicaoSimulada: action.payload };
        default:
            return state;
    }
}
