import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

export default class ModalTable extends Component {

    renderExcluir(index, usuario) {
        return (
            usuario.eleicoes_criadas === 0 && <Button type="button" className="btn-danger btn-sm" onClick={() => this.props.removeUsuario(index, usuario)}>
            <FontAwesomeIcon icon="minus-circle" />
            </Button>
        )
    }

    render() {
        if (this.props.usuarios.length === 0) {
            return '';
        }
                
        return (
            <table className="mt-2 table table-sm table-striped">
                <thead>
                    <tr>
                        <th>Usuário</th>
                        <th>Eleições Criadas</th>
                        <th>Permissão</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.usuarios.map((usuario, index) => {                            
                            return(
                                <tr key={index}>
                                    <td>
                                        {usuario.nome}
                                    </td>
                                    <td>
                                        {usuario.eleicoes_criadas}
                                    </td>
                                    <td>
                                        <Field className="form-control form-control-sm" name={`${usuario.uid.replace(".", "")}___permissao`} component="select">
                                            <option />
                                            <option value="incluir">Incluir</option>
                                            <option value="finalizar">Finalizar</option>
                                            <option value="visualizar">Visualizar</option>
                                            <option value="incluir_finalizar_visualizar">Incluir, finalizar e visualizar</option>
                                        </Field>
                                    </td>
                                    <td>
                                        {this.renderExcluir(index, usuario)}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        );
    }
}