import { SEND_FILE_CDN, DELETE_FILE_CDN } from '../actions/types';

const INITIAL_STATE = { files: [] };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SEND_FILE_CDN:
            return { ...state, files: action.payload.data };
        case DELETE_FILE_CDN:
            return { ...state, files: action.payload.data };    
        default:
            return state;
    }
}
