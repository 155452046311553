import React, { Component } from 'react'
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from '../../../../actions';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap';

import UploadDocumento  from "../../../../utils/UploadDocumento";
import TableDocumentos from "../../../../utils/TableDocumentos";

class Documentos extends Component {
    constructor(props) {
        super(props);
        
        if (this.props.documentos !== undefined) {
            this.state = {
                documentos: this.props.documentos,
                opened: true
            }
        } else {
            this.state = {
                documentos: [],
                opened: true
            };
        }
        
        this.removeDocumento = this.removeDocumento.bind(this);
        this.handleOnAddArquivo = this.handleOnAddArquivo.bind(this);
    }

    componentDidMount() {
        const { documentos, opened } = this.state;
        
        if(documentos.length > 0) {
            this.setState({ opened: !opened });
        }
    }

    removeDocumento(index, documento) {
        let documentos = this.state.documentos;

        const data = new FormData()
        data.append('arquivo_nome', documento.url)

        this.props.deleteFileCdn(data)
        .then(() => {
            documentos.splice(index, 1)
            this.setState({
                documentos
            }, () => {
                this.handleDoc();
            });
        })
    }

    handleOnAddArquivo(data) {
        
        let documentos = this.state.documentos;

        let valida = documentos.filter(function(item){
            return item.url.toLowerCase().search(data.url.toLowerCase()) !== -1
        });
        if(valida.length === 0) {
            documentos.push(data);
        }
        else {
            toastr.error('Atenção', 'O documento ' + data.url + ' já está adicionado nesta eleição!');

            return;
        }

        this.setState({
            documentos
        }, () => {
            this.handleDoc();
        });
    }

    handleDoc() {
        const documentos = this.state.documentos;

        documentos.length > 0 ? this.setState({ opened: false }) : this.setState({ opened: true });
    }

    onSubmit = formProps => {

        formProps.documentos = this.state.documentos;

        this.props.onSubmit();
    };

    render() {
        const { handleSubmit, previousPage } = this.props;
        const { documentos, opened } = this.state;
                
        return (
            <div> 
                <form onSubmit={handleSubmit(this.onSubmit)}>
    
                    <div className="row">
                        <div className="col-12">
                            {opened && <UploadDocumento onAddArquivo={this.handleOnAddArquivo} uploadMultiplo={false} uploadTitle='da eleição' />}
                            
                            <TableDocumentos documentos={documentos} removeDocumento={this.removeDocumento} />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <button type="button" id={'btn-anterior-documentos'} onClick={previousPage} className="btn btn-info">
                                <FontAwesomeIcon icon="arrow-left"/> Anterior
                            </button>
                            <UncontrolledTooltip placement="right" target={'btn-anterior-documentos'}>
                                Voltar para Detalhes
                            </UncontrolledTooltip>
                        </div>

                        <div className="col-6 text-right">
                            <button type="submit" id={'btn-proximo-documentos'} className="btn btn-info">
                                <FontAwesomeIcon icon="arrow-right"/> Salvar/Próximo
                            </button>
                            <UncontrolledTooltip placement="right" target={'btn-proximo-documentos'}>
                                Avançar para Candidatos
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

const selector = formValueSelector('formWizardEleicao')

const mapStateToProps = state => ({
    documentos: selector(state, 'documentos')
});

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ 
        form: 'formWizardEleicao',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true
    })
)(Documentos);