import React from 'react'

export const CheckBox = props => {
    return (
        <div className="frb-group" key={props.id}>
            <div className="frb frb-success">
                <input type="checkbox" id={props.id} onChange={props.handleCheckChieldElement} checked={!!props.ischecked} value={props.id} />
                <label htmlFor={props.id}>
                    <span className="frb-title"><img width="100" src={'https://cdn-0.mpma.mp.br/evoto/'+props.foto} alt="" className="img-responsive img-thumbnail"/></span>
                    <span className="frb-description">{props.nome}</span>
                </label>
            </div>
        </div>
    )
}

export default CheckBox