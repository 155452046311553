import React, { Component } from 'react';
import { connect } from 'react-redux';

export default ChildComponent => {
    class ComposedComponent extends Component {
        componentDidMount() {
            this.shouldNavigateAway();
        }

        componentDidUpdate() {
            this.shouldNavigateAway();
        }

        shouldNavigateAway() {
            if (!this.props.loggedUser.token) {
                this.props.history.push('/login');
            }

            if( ['/liberacao', '/eleicao'].includes(this.props.match.path) && this.props.loggedUser.perfil !== 1) {
                this.props.history.push('/');
            }

            if(this.props.match.path === '/eleicaoliberacao' && ![2, 3].includes(this.props.loggedUser.perfil) ) {
                this.props.history.push('/');
            }

            if( ['/relatorio-zeresima', '/relatorio-votacao'].includes(this.props.match.path) && ![1, 2].includes(this.props.loggedUser.perfil) ) {
                this.props.history.push('/');
            }

        }

        render() {            
            return <ChildComponent { ...this.props } />;
        }
    }

    function mapStateToProps(state) {
        return { loggedUser: state.auth.loggedUser };
    }

    return connect(mapStateToProps)(ComposedComponent);
};