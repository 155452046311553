import { TOGGLE_MODAL_ELEICAO, TOGGLE_MODAL_VOTAR_ELEICAO, TOGGLE_MODAL_PRINT, TOGGLE_MODAL_APURACAO, SET_CANDIDATOS_TRATADOS  } from '../actions/types';

const INITIAL_STATE = { 
    showModalEleicao: false, 
    showModalVotarEleicao: false, 
    showModalPrint: false, 
    showModalApuracao: false,
    candidatosTratados: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case TOGGLE_MODAL_ELEICAO:
            return { ...state, showModalEleicao: action.payload };
        case TOGGLE_MODAL_VOTAR_ELEICAO:
            return { ...state, showModalVotarEleicao: action.payload };
        case TOGGLE_MODAL_PRINT:
            return { ...state, showModalPrint: action.payload };
        case TOGGLE_MODAL_APURACAO:
            return { ...state, showModalApuracao: action.payload }; 
        case SET_CANDIDATOS_TRATADOS:
            return { ...state, candidatosTratados: action.payload};     
        default:
            return state;
    }
}
