import React from 'react';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import * as actions from '../../../../actions';
import moment from 'moment';

import DetalhesEdit from './DetalhesEdit';
import DocumentosEdit from './DocumentosEdit';
import CandidatosEdit from './CandidatosEdit';
import EleitoresEdit from './EleitoresEdit';
import confirm from 'reactstrap-confirm';
import { JsonToTable } from "react-json-to-table";

class WizardFormEdit extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            documentos: [],
            candidatos: [],
            comarcas: [],
            setores: [],
            vinculos: [],
            usuarios: [],
            tipo_eleicao: 1,
            qtda_eleitores: 0,
            initData: {},
            page: 1
        };

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.eleicao !== undefined) {
            const initData = {
                // DETALHES
                liberacao_id: this.props.eleicao.liberacao_id,
                liberacao_descricao: this.props.eleicao.liberacao_descricao,
                data_inicial: moment(this.props.eleicao.data_inicial),
                data_final: moment(this.props.eleicao.data_final),
                liberacao_data_inicial: this.props.eleicao.liberacao_data_inicial,
                liberacao_data_final: this.props.eleicao.liberacao_data_final,
                descricao: this.props.eleicao.descricao,
                branco: this.props.eleicao.branco,
                selecao_candidato: this.props.eleicao.selecao_candidato,
                qtda_selecao_candidato: this.props.eleicao.qtda_selecao_candidato,

                documentos: this.props.eleicao.documentos,
                candidatos: this.props.eleicao.candidatos,
                // ELEITORES
                comarcas: this.props.eleicao.comarcas,
                setores: this.props.eleicao.setores,
                vinculos: this.props.eleicao.vinculos,
                usuarios: this.props.eleicao.usuarios,
                tipo_eleicao: this.props.eleicao.tipo_eleicao,
                qtda_eleitores: this.props.eleicao.qtda_eleitores
            };

            const documentos = this.props.eleicao.documentos;
            const candidatos = this.props.eleicao.candidatos;
                        
            this.setState({ candidatos, documentos, initData });
        }
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 });
    }

    onSubmit(formProps) {
        this.setState({ submitDisabled: true })

        let serverData = {
            'data_inicial': formProps.dataInicial,
            'data_final': formProps.dataFinal,
            'descricao': formProps.descricao,
            'selecao_candidato': parseInt(formProps.selecao_candidato,10),
            'qtda_selecao_candidato': (parseInt(formProps.selecao_candidato,10) === 2 ? parseInt(formProps.qtda_selecao_candidato, 10) : 0),
            'qtda_eleitores': parseInt(formProps.qtda_eleitores, 10),
            'branco': formProps.branco,
            'candidatos': formProps.candidatos,
            'documentos': formProps.documentos,
            'tipo_eleicao': parseInt(formProps.tipo_eleicao, 10),
            'lib_comarcas': formProps.comarcas,
            'lib_setores': formProps.setores,
            'lib_vinculos': formProps.vinculos,
            'lib_usuarios': formProps.usuarios,
            'ip': this.props.ipUsuario
        };

        this.props.editEleicao(this.props.eleicao.id, serverData, (msg) => {
            try {
                toastr.success('Sucesso', msg);

                this.props.fetchEleicoes(() => {
                    this.props.closeModal();
                    this.setState({ submitDisabled: false });
                    this.props.setLiberacaoId([]);
                });

                this.props.fetchEleicoesUsuario(() => {});

            } catch (error) {
                toastr.success('Erro', error);

                this.setState({ submitDisabled: false });
            }
            
        });
    }

    verificaValores = async (values) => {
        try {
            const myJson = JSON.stringify(values, null, 2);
            var jsonObj = JSON.parse(myJson);
            const result = await confirm({
                title: "Confirme a edição da eleição",
                message: (
                    <React.Fragment>
                    <JsonToTable json={jsonObj} />
                    </React.Fragment>
                ),
                confirmText: "Confirmar",
                cancelText: "Cancelar",
                confirmColor: "primary",
                cancelColor: "danger"
            });
            if(result) {
                this.onSubmit(values);
            }
        } catch(error) {
            toastr.error('Atenção', error);
        } finally {
            // final
        }
    }

    render() {
        const { page, submitDisabled } = this.state;
        
        if (this.state.candidatos.length > 0) {

            switch(page) {
                case 1:
                    return (
                        <DetalhesEdit 
                            onSubmit={this.nextPage}
                            initData={this.state.initData} 
                        />
                    )
                case 2:
                    return (
                        <DocumentosEdit
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                        />
                )    
                case 3:
                    return (
                        <CandidatosEdit
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                        />
                )
                case 4:
                    return (
                        <EleitoresEdit
                            previousPage={this.previousPage}
                            onSubmit={this.onSubmit}
                            initData={this.state.initData} 
                            submitDisabled={submitDisabled}
                        />
                )
                default:
                    break;
            }

        } else {
            return ('')
        }
    }
}

function mapStateToProps(state) {
    return { ipUsuario: state.auth.ipUsuario };
}

export default connect(mapStateToProps, actions)(WizardFormEdit);
