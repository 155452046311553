import React from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    Badge,
    Row,
    Col,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Moment from "react-moment";
import * as actions from "../../../actions";
import { filterTableMethod } from "../../../utils/renderField";

class ModalView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showModalViewEleicao: false,
            activeTab: "1",
            activeTabPai: "1",
            activeTabConfig: "1",
        };

        this.toggle = this.toggle.bind(this);
        this.toggleTabs = this.toggleTabs.bind(this);
        this.toggleTabsPai = this.toggleTabsPai.bind(this);
        this.toggleTabsConfig = this.toggleTabsConfig.bind(this);
        this.viewEleicaoSimulada = this.viewEleicaoSimulada.bind(this);
    }

    toggle() {
        this.setState({ showModal: !this.state.showModal });
    }

    toggleTabs(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleTabsPai(tab) {
        if (this.state.activeTabPai !== tab) {
            this.setState({
                activeTabPai: tab,
            });
        }
    }

    toggleTabsConfig(tab) {
        if (this.state.activeTabConfig !== tab) {
            this.setState({
                activeTabConfig: tab,
            });
        }
    }

    viewEleicaoSimulada(idEleicao) {
        this.props.getEleicaoSimulada(idEleicao, () => {
            this.setState({ showModalViewEleicao: true });
        });
    }

    componentWillReceiveProps(props) {
        this.setState({ showModal: props.showModal });
    }

    renderCandidatosTable(candidatos) {
        if (candidatos.length === 0) {
            return "Esta eleição não possui candidatos.";
        }

        return (
            <ReactTable
                data={candidatos}
                columns={[
                    {
                        Header: "Candidatos",
                        columns: [
                            {
                                Header: "#",
                                accessor: "id",
                                filterable: false,
                                maxWidth: 40,
                                Cell: row => <>{row.index + 1}</>,
                            },
                            {
                                Header: "Foto",
                                accessor: "foto",
                                filterable: false,
                                maxWidth: 60,
                                Cell: row => (
                                    <img
                                        width="50"
                                        src={`https://cdn-0.mpma.mp.br/evoto/${row.value}`}
                                        alt=""
                                        className="img-responsive img-thumbnail"
                                    />
                                ),
                            },
                            {
                                Header: "Nome",
                                accessor: "nome",
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "index",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderEleitoresTable(eleitores) {
        if (eleitores.length === 0) {
            return "Esta eleição não possui eleitores.";
        }

        return (
            <ReactTable
                data={eleitores}
                columns={[
                    {
                        Header: "Eleitores",
                        columns: [
                            {
                                Header: "#",
                                accessor: "id",
                                filterable: false,
                                maxWidth: 40,
                                Cell: row => <>{row.index + 1}</>,
                            },
                            {
                                Header: "Nome",
                                accessor: "nome",
                            },
                            {
                                Header: "Comarca",
                                accessor: "comarca",
                            },
                            {
                                Header: "Lotação",
                                accessor: "lotacao",
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "index",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderDocumentosTable(documentos) {
        if (documentos.length === 0) {
            return "Esta eleição não possui documentos.";
        }

        return (
            <ReactTable
                data={documentos}
                columns={[
                    {
                        Header: "Documentos",
                        columns: [
                            {
                                Header: "#",
                                accessor: "id",
                                filterable: false,
                                maxWidth: 40,
                                Cell: row => <>{row.index + 1}</>,
                            },
                            {
                                Header: "Nome do Documento",
                                accessor: "url",
                                Cell: row => (
                                    <Badge
                                        href={`https://cdn-0.mpma.mp.br/evoto/${row.value}`}
                                        target="_blank"
                                        color="light"
                                    >
                                        {row.value}
                                    </Badge>
                                ),
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "index",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderComarcasTable(comarcas) {
        if (comarcas.length === 0) {
            return "Esta eleição não possui comarcas.";
        }

        return (
            <ReactTable
                data={comarcas}
                columns={[
                    {
                        Header: "Comarcas",
                        columns: [
                            {
                                Header: "Nome",
                                accessor: "label",
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "label",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderLotacoesTable(setores) {
        if (setores.length === 0) {
            return "Esta eleição não possui lotações";
        }

        return (
            <ReactTable
                data={setores}
                columns={[
                    {
                        Header: "Lotações",
                        columns: [
                            {
                                Header: "Nome",
                                accessor: "label",
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "label",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderUsuariosTable(usuarios) {
        if (usuarios.length === 0) {
            return "Esta eleição não possui usuários";
        }

        return (
            <ReactTable
                data={usuarios}
                columns={[
                    {
                        Header: "Usuários",
                        columns: [
                            {
                                Header: "Nome",
                                accessor: "label",
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "label",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderVinculosTable(vinculos) {
        if (vinculos.length === 0) {
            return "Esta eleição não possui vínculos";
        }

        return (
            <ReactTable
                data={vinculos}
                columns={[
                    {
                        Header: "Vínculos",
                        columns: [
                            {
                                Header: "Nome",
                                accessor: "label",
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "label",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderDetalhes(detalhes) {
        return (
            <Row>
                <Col sm="12">
                    <div className="row">
                        <div className="col-6">
                            <strong>Data Inicial:</strong>{" "}
                            <Moment format="DD/MM/YYYY HH:mm">
                                {detalhes.data_inicial}
                            </Moment>
                        </div>
                        <div className="col-6">
                            <strong>Data Final:</strong>{" "}
                            <Moment format="DD/MM/YYYY HH:mm">
                                {detalhes.data_final}
                            </Moment>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-6">
                            <strong>Data de Criação:</strong>{" "}
                            <Moment format="DD/MM/YYYY HH:mm">
                                {detalhes.timecreated}
                            </Moment>
                        </div>
                        {detalhes.timeupdated && (
                            <div className="col-6">
                                <strong>Data de Atualização:</strong>{" "}
                                <Moment format="DD/MM/YYYY HH:mm">
                                    {detalhes.timeupdated}
                                </Moment>
                            </div>
                        )}
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-7">
                            <strong>Descrição: </strong>
                            {detalhes.descricao}
                        </div>
                        <div className="col-5">
                            <strong>Qtd Estimada de Eleitores: </strong>
                            {detalhes.qtda_eleitores}
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-4">
                            <strong>Voto Branco: </strong>
                            {detalhes.branco === 1 ? "Sim" : "Não"}
                        </div>
                        <div className="col-4">
                            <strong>Seleção Candidato: </strong>
                            {detalhes.selecao_candidato === 1
                                ? "Único"
                                : "Múltiplo"}
                        </div>
                        {detalhes.selecao_candidato === 2 ? (
                            <div className="col-4">
                                <strong>Quant. Máxima: </strong>
                                {detalhes.qtda_selecao_candidato}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                    <hr />

                    <p>
                        <strong>Status: </strong>
                        {detalhes.status === "fechada" ||
                        detalhes.status === "expirada" ? (
                            <span className="badge badge-pill badge-danger">
                                {detalhes.status}
                            </span>
                        ) : detalhes.status === "iniciada" ? (
                            <span className="badge badge-pill badge-success">
                                {detalhes.status}
                            </span>
                        ) : detalhes.status === "a iniciar" ? (
                            <span className="badge badge-pill badge-warning">
                                {detalhes.status}
                            </span>
                        ) : (
                            <span className="badge badge-pill badge-info">
                                {detalhes.status}
                            </span>
                        )}
                    </p>

                    <hr />
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "1",
                                })}
                                onClick={() => {
                                    this.toggleTabs("1");
                                }}
                            >
                                Candidatos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "2",
                                })}
                                onClick={() => {
                                    this.toggleTabs("2");
                                }}
                            >
                                Votantes Concluídos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "3",
                                })}
                                onClick={() => {
                                    this.toggleTabs("3");
                                }}
                            >
                                Documentos
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {this.renderCandidatosTable(
                                        detalhes.candidatos,
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    {this.renderEleitoresTable(
                                        detalhes.eleitores,
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    {this.renderDocumentosTable(
                                        detalhes.documentos,
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        );
    }

    renderConfiguracao(detalhes) {
        return (
            <Row>
                <Col sm="12">
                    <p>
                        Seleção de Candidato :{" "}
                        {detalhes.selecao_candidato === 1
                            ? "Único"
                            : "Múltiplo"}
                    </p>
                    <p>
                        Tipo Eleição :{" "}
                        {detalhes.tipo_eleicao === 1 ? "Aberta" : "Fechada"}
                    </p>
                    <hr />

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTabConfig === "1",
                                })}
                                onClick={() => {
                                    this.toggleTabsConfig("1");
                                }}
                            >
                                Comarcas
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTabConfig === "2",
                                })}
                                onClick={() => {
                                    this.toggleTabsConfig("2");
                                }}
                            >
                                Lotações
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTabConfig === "3",
                                })}
                                onClick={() => {
                                    this.toggleTabsConfig("3");
                                }}
                            >
                                Vínculos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTabConfig === "4",
                                })}
                                onClick={() => {
                                    this.toggleTabsConfig("4");
                                }}
                            >
                                Usuários
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTabConfig}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {this.renderComarcasTable(
                                        detalhes.comarcas,
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    {this.renderLotacoesTable(detalhes.setores)}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    {this.renderVinculosTable(
                                        detalhes.vinculos,
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    {this.renderUsuariosTable(
                                        detalhes.usuarios,
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        );
    }

    render() {
        if (!this.props.eleicao) {
            return "";
        }

        return (
            <div>
                <Modal
                    className="modal-evoto"
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    size="xl"
                >
                    <ModalHeader toggle={this.toggle}>
                        Detalhes da Eleição {this.props.eleicao.id}
                    </ModalHeader>
                    <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTabPai === "1",
                                    })}
                                    onClick={() => {
                                        this.toggleTabsPai("1");
                                    }}
                                >
                                    Eleição Original
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTabPai === "2",
                                    })}
                                    onClick={() => {
                                        this.toggleTabsPai("2");
                                    }}
                                >
                                    Eleição Simulada
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTabPai === "3",
                                    })}
                                    onClick={() => {
                                        this.toggleTabsPai("3");
                                    }}
                                >
                                    Liberação
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTabPai === "4",
                                    })}
                                    onClick={() => {
                                        this.toggleTabsPai("4");
                                    }}
                                >
                                    Eleitores Configurados
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTabPai}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        {this.renderDetalhes(
                                            this.props.eleicao,
                                        )}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        {this.renderDetalhes(
                                            this.props.eleicao.simulada,
                                        )}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>Data Inicial:</strong>{" "}
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                    {
                                                        this.props.eleicao
                                                            .liberacao_data_inicial
                                                    }
                                                </Moment>
                                            </div>
                                            <div className="col-6">
                                                <strong>Data Final:</strong>{" "}
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                    {
                                                        this.props.eleicao
                                                            .liberacao_data_final
                                                    }
                                                </Moment>
                                            </div>
                                        </div>

                                        <p>
                                            <strong>Descrição: </strong>
                                            {
                                                this.props.eleicao
                                                    .liberacao_descricao
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <Row>
                                    <Col sm="12">
                                        {this.renderConfiguracao(
                                            this.props.eleicao,
                                        )}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { eleicao: state.eleicao.eleicao };
}

export default connect(mapStateToProps, actions)(ModalView);
