import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import * as actions from '../../../actions';
import { ROOT_URL } from '../../../utils/Global';
import { toastr } from "react-redux-toastr";
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';
import ModalPrint from '../../relatorio/ModalPrint';

class ModalStart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalStart: false,
            candidatos : [],
            timerId: 0,
            apiEncrypt: null
        };

        this.intervalID = 0;
        this.toggle = this.toggle.bind(this);
        this.printEleicaoZeresima = this.printEleicaoZeresima.bind(this);
    }

    toggle() {
        this.setState({ showModalStart: !this.state.showModalStart });
        
        clearInterval(this.intervalID);
    }

    componentWillReceiveProps(props) {
        this.setState({ showModalStart: props.showModalStart })
    }

    renderIniciarEleicao(eleicao, showModalStart) {
        if(showModalStart) {
            this.intervalID = setInterval(() => 
                this.props.eleicaoIniciada(eleicao.id, (data) => {
                    if(data) {
                        this.setState({ showModalStart: false });
                        toastr.success('Sucesso', 'Eleição Iniciada');
                        this.props.fetchEleicoes(() => {
                            this.printEleicaoZeresima(parseInt(eleicao.id, 10), eleicao.descricao, eleicao.qtda_selecao_candidato, eleicao.data_inicial, eleicao.data_final);
                        });
                        this.props.fetchEleicoesUsuario(() => {});
                        clearInterval(this.intervalID);
                    } else {
                        setTimeout(() => { 
                            clearInterval(this.intervalID)
                            this.setState({ showModalStart: false });
                        }, 120000);
                    }
                })
                , 3000);
        } else
        clearInterval(this.intervalID);
    }

    printEleicaoZeresima(idEleicao, descEleicao, qtdaSelecaoCandidato) {          
        this.props.getZeresima(idEleicao, false, (result) => {
            this.props.tratarCandidatos(result.data.votacao, qtdaSelecaoCandidato);
            this.props.toggleModalPrint(!this.props.showModalPrint);
        })
    };

    render() {

        const { eleicao, loggedUser, ipUsuario } = this.props;
        const { showModalStart, showModalPrint } = this.state;

        if (!eleicao) {
            return '';
        }

        const dataEncrypt = [{
            id: eleicao.id, 
            uid: loggedUser.uid,
            ip: ipUsuario
        }]

        // Encrypt
        const apiEncrypt = CryptoJS.AES.encrypt(JSON.stringify(dataEncrypt), 'y*C:2cjYL?zWH#ZW').toString();

        // Decrypt
        //const bytes  = CryptoJS.AES.decrypt(apiEncrypt, 'y*C:2cjYL?zWH#ZW');
        //const apiDecrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        //console.log(`${ROOT_URL}/start/${apiEncrypt}`);
        //console.log(apiDecrypt);

        return (
            <div>
                {this.renderIniciarEleicao(eleicao, showModalStart)}
                <Modal isOpen={showModalStart} toggle={this.toggle} centered={true} size="sm">
                    <ModalHeader toggle={this.toggle}>Iniciar Eleição #{eleicao.id}</ModalHeader>
                    <ModalBody>
                        {apiEncrypt && <QRCode value={`${ROOT_URL}/start/${apiEncrypt}`} level="L" renderAs="svg" size={267} bgColor="#FFFFFF" fgColor="#000000" />}
                        {/* <a href={`${ROOT_URL}/start/${apiEncrypt}`} target="_blank" rel="noopener noreferrer"> LINK_TESTE </a> */}
                    </ModalBody>
                </Modal>

                <ModalPrint 
                    showModalPrint={showModalPrint} 
                    modalTipo='zeresima'
                    idEleicao={eleicao.id}
                    descEleicao={eleicao.descricao} 
                    qtdaSelecaoCandidato={eleicao.qtda_selecao_candidato}
                    dataInicial={eleicao.data_inicial} 
                    dataFinal={eleicao.data_final} 
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { 
        eleicao: state.eleicao.eleicao,
        loggedUser: state.auth.loggedUser,
        ipUsuario: state.auth.ipUsuario
    };
}

export default connect(mapStateToProps, actions)(ModalStart);