import React from 'react';
import { Label, Input, InputGroup, InputGroupAddon, UncontrolledTooltip, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TIME_ZONE } from '../utils/Global';
import moment from 'moment';

import Select from 'react-select';
import * as Datetime from 'react-datetime';
import InputMask from 'react-input-mask';

import 'moment-timezone';
import 'moment/locale/pt-br';

moment.globalLocale = 'pt-BR';
moment.tz.setDefault(TIME_ZONE);

export const dataAtual = moment();

const anoMinimo = moment().subtract(10, 'years').format('YYYY');
const isValidDate = current => current.year() >= anoMinimo;

export const required = value => (value || typeof value === 'number' ? undefined : 'Obrigatório')
export const maxLength = max => value => value && value.length > max ? `Tamanho máximo de ${max} caracteres` : undefined
export const minLength = min => value => value && value.length < min ? `Tamanho mínimo de ${min} caracteres` : undefined
export const number = value => value && isNaN(Number(value)) ? 'Deve ser um número' : undefined
export const minValue = min => value => value && value < min ? `Deve ter pelo menos ${min}` : undefined
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Email inválido' : undefined
export const alphaNumeric = value => value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Somente caracteres alfanuméricos' : undefined
export const phoneNumber = value => value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Telefone inválido' : undefined
export const validDateTime = value => value && !moment(value).isValid() ? 'Data ou hora inválida' : undefined
export const anoInicial = value => value && moment(value).format('YYYY') < anoMinimo ? `Ano mínimo ${anoMinimo}` : undefined

export const maiorPalavra = max => value => {
    if(value) {
        const nome = value.split(' ');
        const candidatoMaior = nome.filter(_ => _.length > max);
        return (candidatoMaior.length >= 1) ? `A maior palavra da língua portuguesa tem ${max} letras, tem certeza que informou os dados corretamente?` : undefined
    }
    return undefined
}

export const filterTableMethod = (filter, row) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(convertToSlug(row[id]).toLowerCase()).indexOf(convertToSlug(filter.value).toLowerCase()) >= 0 : true;
}

export const maioPalavra46 = maiorPalavra(46)
export const minValue0 = minValue(0)
export const minValue1 = minValue(1)
export const minValue13 = minValue(13)
export const minLength3 = minLength(3)
export const maxLength9 = maxLength(9)
export const maxLength15 = maxLength(15)
export const maxLength255 = maxLength(255)

export const convertToSlug = (text) => {
    text = text || "";
    const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')
    return text.toString().toLowerCase().trim()
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[\s\W-]+/g, '-')
}

export const renderField = ({ input, label, type, required, meta:{ touched, error }}) => (
    <div>
        <label>{label}{required === true ? <span className="text-danger">*</span> : ''}</label>
        <div>
            <Input
                {...input}
                placeholder={label}
                type={type}
                invalid={ touched && error ? true : false } 
            />
            {touched && (error && <span className="invalid-feedback">{error}</span>)}
        </div>
    </div>
);

export const renderFieldGroupButton = ({ input, id, icon, label, type, msgBtn, disabled, handleSubmit, handleClear, required, meta:{ touched, error }}) => (
    <div>
        <Label id={id}>{label}{required === true ? <span className="text-danger">*</span> : ''}</Label>
        <div>
            <InputGroup>
                <Input
                    {...input}
                    id={id}
                    type={type}
                    placeholder={label}
                    invalid={ touched && error ? true : false }
                />
                <InputGroupAddon addonType="append">
                    <Button type="submit" id={'btn-'+id} disabled={disabled} onClick={handleSubmit} color="success">
                        <FontAwesomeIcon icon={icon}/> {msgBtn}
                    </Button>
                    <UncontrolledTooltip placement="top" target={'btn-'+id}>
                        {msgBtn}
                    </UncontrolledTooltip>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                    <Button id={'btn-limpar'+id} onClick={handleClear} color="danger">
                        <FontAwesomeIcon icon="trash-alt"/>
                    </Button>
                    <UncontrolledTooltip placement="top" target={'btn-limpar'+id}>
                        Limpar
                    </UncontrolledTooltip>
                </InputGroupAddon>
                {touched && (error && <span className="invalid-feedback">{error}</span>)}
            </InputGroup>
        </div>
    </div>
);

export const renderDateField = ({ input, label, type, required, max, meta: { touched, error }}) => (
    <div>
        <label>{label}{required === true ? <span className="text-danger">*</span> : ''}</label>
        <div>
            <Input
                {...input}
                placeholder={label}
                type={type}
                max={max}
                invalid={ touched && error ? true : false } 
            />
            {touched && (error && <span className="invalid-feedback">{error}</span>)}
        </div>
    </div>
);

export const renderDateTimeField = ({ input, label, defaultValue, required, meta: { touched, error }}) => (
    <div>
        <label>{label}{required === true ? <span className="text-danger">*</span> : ''}</label>
        <div>
            <Datetime
                {...input}
                renderInput={ renderMaskDate }
                inputProps={{ placeholder: label, className: 'form-control' + (touched && error ? ' is-invalid' : '') }}
                isValidDate={ isValidDate }
                defaultValue={ defaultValue }
            />
            {touched && (error && <span className="invalid-feedback-date">{error}</span>)}
        </div>
    </div>
);

export const renderSelectField = ({ input, label, options, defaultValue, required, meta: { touched, error }}) => (
    <div>
        <label>{label}{required === true ? <span className="text-danger">*</span> : ''}</label>
        <div>
            <Select
                {...input}
                placeholder={`Selecionar ${label}`}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={defaultValue}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                options={options}
            />
            {touched && (error && <span className="invalid-feedback">{error}</span>)}
        </div>
    </div>
);

const renderMaskDate = props => {
    return (
        <div>
            <InputMask 
            {...props}
                mask="99/99/9999 99:99" 
            />
        </div>
    );
};
