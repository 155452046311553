import React, { Component } from "react";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import * as actions from "../actions";

class LogoutTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
        };
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
        this.onAction = this._onAction.bind(this);
    }

    componentWillMount() {
        let timeactive = localStorage.getItem("timeactive");
        if (timeactive < Date.now()) {
            this._onIdle();
        } else {
            localStorage.setItem("timeactive", Date.now() + 10 * 1000 * 60);
        }
        setInterval(() => {
            let timeactive = localStorage.getItem("timeactive");
            if (timeactive < Date.now()) {
                this._onIdle();
            } else if (this.state.active) {
                localStorage.setItem("timeactive", Date.now() + 10 * 1000 * 60);
                this.setState({active: false});
            }
        }, 30 * 100);
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onAction={this.onAction}
                    debounce={200}
                />
            </div>
        );
    }

    _onAction(e) {
        this.setState({ active: false });
    }

    _onIdle(e) {
        if (this.props.loggedUser.hasOwnProperty("token")) {
            this.props.logout();
        }
    }
}

function mapStateToProps(state) {
    return { loggedUser: state.auth.loggedUser };
}

export default connect(mapStateToProps, actions)(LogoutTime);
