import React from 'react';
import Dropzone from 'react-dropzone'
import { Button, ListGroup, ListGroupItem, Fade } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as actions from "../actions";
import { convertToSlug } from '../utils/renderField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const baseStyle = {
  padding: 20,
  borderWidth: 2,
  textAlign: 'center',
  borderColor: '#666',
  borderStyle: 'dashed',
  backgroundColor: '#eee',
  borderRadius: 5
};
const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};
const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

class UploadDocumento extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        arquivos: [],
        msgBtn: (this.props.uploadMultiplo ? 'Enviar Documentos' : 'Enviar Documento'),
        setDisabled: true,
        fadeIn: false 
      }

      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    onDrop(arquivos) {
      this.setState({ arquivos, setDisabled: false, fadeIn: true });
    }
  
    onCancel() {
      this.setState({ arquivos: [] });
    }

    removeArquivo(index) {
      let arquivos = this.state.arquivos;

      arquivos.splice(index, 1);

      if(arquivos.length === 0) {
        this.setState({ arquivos, setDisabled: true, fadeIn: false }); 
      } else {
        this.setState({ arquivos, setDisabled: false }); 
      }
    }

    renameFilename(file) {
      const uid = (new Date().getTime()).toString(10);

      let nome = file.slice(0, -4);

      if(nome.length > 60) {
        nome = nome.substring(0, 60)
      }

      return convertToSlug(nome)+"_"+ uid +"." + file.split('.').pop();
    }

    handleSubmit(e) {
      e.preventDefault();

      if (this.state.arquivos !== null) {

        const data = new FormData();

        this.state.arquivos.forEach( arquivo => {
          data.append('arquivo_data[]', arquivo, this.renameFilename(arquivo.name) )
        })
        
        this.setState({ setDisabled: true, msgBtn: 'Aguarde...' });
        
        this.props.sendFileCdn(data)
          .then(() => {
            data.forEach(arquivo => {
              this.props.onAddArquivo({ url: arquivo.name });
            })
            this.setState({ setDisabled: false, fadeIn: false, msgBtn: (this.props.uploadMultiplo ? 'Enviar Documentos' : 'Enviar Documento'), arquivos: [] }); 
          })
      }
    }
  
    render() {
      const arquivos = this.state.arquivos.map((arquivo, index) => (
          <ListGroupItem key={index}>
            {this.renameFilename(arquivo.name)} &nbsp;
            <Button type="button" className="btn-danger btn-sm btn pull-right" onClick={() => this.removeArquivo(index)}>
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </ListGroupItem>
      ))

      return (
        <section className="mt-3">
          <Dropzone 
            multiple={this.props.uploadMultiplo}
            onDrop={this.onDrop.bind(this)}
            onFileDialogCancel={this.onCancel.bind(this)}
            >
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
            let styles = {...baseStyle}
            styles = isDragActive ? {...styles, ...activeStyle} : styles
            styles = isDragReject ? {...styles, ...rejectStyle} : styles
                  
            return (
              <div 
                {...getRootProps()}
                style={styles}
              >
                <input {...getInputProps()} />
                <div>
                  {isDragAccept ? 'Solte' : 'Arraste'} {this.props.uploadMultiplo ? ` os arquivos ${this.props.uploadTitle} aqui ou clique para selecionar arquivos` : ` o arquivo ${this.props.uploadTitle} aqui ou clique para selecionar arquivo`}
                </div>
              </div>
            )
          }}
        </Dropzone>
          <Fade in={this.state.fadeIn} hidden={!this.state.fadeIn} className="mt-3 mb-3">
            <aside>
                <ListGroup>{arquivos}</ListGroup>
                <Button type="button" className="mb-3 mt-3 btn btn-success pull-right" disabled={this.state.setDisabled} onClick={this.handleSubmit}><FontAwesomeIcon icon="plus-circle" /> {this.state.msgBtn}</Button>
            </aside>
          </Fade>
        </section>
      );
    }
  }

  export default compose(
    connect(null, actions),
    reduxForm({ form: 'UploadDocumento' })
)(UploadDocumento);
