import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap';

import LiberacaoBusca from './LiberacaoBusca';
import LiberacaoTable from './LiberacaoTable';
import ModalCreate from "./modal/ModalCreate";

class LiberacaoGridPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    renderAddLiberacao() {
        return (
            <React.Fragment>
                <button id={'btn-add'} onClick={() => this.toggleModal()} className="btn btn-success btn-sm ml-1">
                    <FontAwesomeIcon icon="plus-circle"/> Criar nova Liberação
                </button>
                <UncontrolledTooltip placement="right" target={'btn-add'}>
                    Criar nova Liberação
                </UncontrolledTooltip>
            </React.Fragment>
        )
    }

    render() {
        return(
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Manter Liberações</h1>
                    {this.renderAddLiberacao()}
                </div>

                <LiberacaoBusca />

                <LiberacaoTable />

                <ModalCreate showModal={this.state.showModal} toggleModal={() => this.toggleModal()} />
            </div>
        );
    }
}

export default LiberacaoGridPage;
