import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import { validDateTime, anoInicial, renderField, renderDateTimeField } from '../../utils/renderField';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { reduxForm, Field } from 'redux-form';
import * as actions from '../../actions';
import Select from 'react-select';

const optionsEleicoes = [
    { label: 'Todos', value: '' },
    { label: 'Aberta', value: 'aberta' },
    { label: 'Fechada', value: 'fechada' },
    { label: 'Iniciada', value: 'iniciada' },
    { label: 'Expirada', value: 'expirada' },
];

class EleicaoUsuarioBusca extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tipoSelecionado: { label: 'Todos', value: '' }
        };

        this.clearForm = this.clearForm.bind(this);
    }

    onSubmit = formProps => {

        if ((formProps.data_inicial && formProps.data_final) && (formProps.data_inicial >= formProps.data_final)) {
            toastr.error('Atenção', 'A data e hora final deve ser maior que a data e hora inicial');

            return;
        }

        let status = this.state.tipoSelecionado.value;

        let params = 'p=' + true;

        if (formProps.candidato) {
            params += '&candidato=' + formProps.candidato;
        }

        if (formProps.descricao_eleicao) {
            params += '&descricao_eleicao=' + formProps.descricao_eleicao;
        }

        if (formProps.data_inicial) {
            params += '&data_inicial=' + moment(formProps.data_inicial).format('YYYY-MM-DDTHH:mm');
        }

        if (formProps.data_final) {
            params += '&data_final=' + moment(formProps.data_final).format('YYYY-MM-DDTHH:mm');
        }

        this.props.filterEleicoesUsuario(status, params);
    };

    clearForm() {
        const initData = {
            candidato: '',
            descricao_eleicao: '',
            data_inicial: '',
            data_final: ''
        };

        this.props.initialize(initData);

        this.props.filterEleicoesUsuario('');

        this.props.fetchEleicoesUsuario(() => {
            this.setState({ isLoading: false, tipoSelecionado: { label: 'Todos', value: '' } });
        });
    }

    onChangeTipoEleicao = (tipoSelecionado) => {
        this.setState({ tipoSelecionado });
    }

    render () {
        const { handleSubmit } = this.props;
        const { tipoSelecionado } = this.state;

        return (
            <div>
                <Button color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                    Filtrar dados
                </Button>
                <UncontrolledCollapse toggler="#toggler" style={{ marginBottom: '1rem' }}>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(this.onSubmit)}>
                                <div className="row">
                                    <div className="col-6">
                                        <Field
                                            name="candidato"
                                            type="text"
                                            label="Candidato"
                                            component={renderField}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Field
                                            name="data_inicial"
                                            label="Data inicial"
                                            validate={[validDateTime, anoInicial]}
                                            component={renderDateTimeField}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Field
                                            name="data_final"
                                            label="Data final"
                                            validate={[validDateTime, anoInicial]}
                                            component={renderDateTimeField}
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '1rem' }}>
                                    <div className="col-10">
                                        <Field
                                            name="descricao_eleicao"
                                            type="text"
                                            label="Descrição da Eleição"
                                            component={renderField}
                                        />
                                    </div>
                                    <div className="className col-2">
                                        <label>Status de Eleição</label>
                                        <Select
                                            value={tipoSelecionado}
                                            onChange={this.onChangeTipoEleicao}
                                            options={optionsEleicoes}
                                        />
                                    </div>
                                    <div className="col-2" style={{paddingTop: '33px'}}>
                                        <button
                                            type="button"
                                            onClick={this.clearForm}
                                            className="btn btn-sm btn-dark mr-1">Limpar</button>
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-primary">Filtrar</button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </div>
        );
    }
}

export default compose(
    connect(null, actions),
    reduxForm({ form: 'formEleicaoUsuarioBusca', initialValues: { candidato: '', descricao_eleicao: '', data_inicial: '', data_final: ''} })
)(EleicaoUsuarioBusca);