import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import BuscaZeresima from "./BuscaZeresima";
import ZeresimaTable from "./ZeresimaTable";

class Zeresima extends React.Component {
    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h2 className="h2">Relatório de Zerésima</h2>
                </div>

                <BuscaZeresima />

                <ZeresimaTable />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { loggedUser: state.auth.loggedUser };
}

export default connect(mapStateToProps, actions)(Zeresima);
