import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import { validDateTime, anoInicial, renderDateTimeField } from '../../utils/renderField';
import moment from 'moment';
import { reduxForm, Field } from 'redux-form';
import * as actions from '../../actions';
import AsyncSelect from 'react-select/lib/Async';

class EleicaoBusca extends Component {

    constructor(props) {
        super(props);

        this.state = {
            comarca: null, lotacao: null, vinculo: null, usuario: null
        };

        this.clearForm = this.clearForm.bind(this);
    }
    
    onSubmit = formProps => {

        const { comarca, lotacao, vinculo, usuario } = this.state;

        if ((formProps.data_inicial && formProps.data_final) && (formProps.data_inicial >= formProps.data_final)) {
            toastr.error('Atenção', 'A data e hora final deve ser maior que a data e hora inicial');

            return;
        }

        let params = 'p=' + true

        if (usuario !== null) {
            params += '&usuario=' + usuario.value;
        }

        if (vinculo !== null) {
            params += '&vinculo=' + vinculo.value;
        }

        if (comarca !== null) {
            params += '&comarca=' + comarca.value;
        }

        if (lotacao !== null) {
            params += '&lotacao=' + lotacao.value;
        }

        if (formProps.data_inicial) {
            params += '&data_inicial=' + moment(formProps.data_inicial).format('YYYY-MM-DDTHH:mm');
        }

        if (formProps.data_final) {
            params += '&data_final=' + moment(formProps.data_final).format('YYYY-MM-DDTHH:mm');
        }

        this.props.filterEleicoes(params);
    };

    clearForm() {
        const initData = {
            data_inicial: '',
            data_final: ''
        };

        this.props.initialize(initData);
        this.setState({ comarca: null, lotacao: null, vinculo: null, usuario: null });
        this.props.filterEleicoes('');
    }

    handleChangeComarcas = (comarca) => {
        this.setState({ comarca });
    }

    handleChangeSetores = (lotacao) => {
        this.setState({ lotacao });
    }

    handleChangeVinculos = (vinculo) => {
        this.setState({ vinculo });
    }

    handleChangeUsuarios = (usuario) => {
        this.setState({ usuario });
    }

    loadOptionsComarcas = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('comarcas', inputValue, (comarcas) => {                
                callback(comarcas);
            });
        }
    };

    loadOptionsSetores = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('setores', inputValue, (setores) => {                
                callback(setores);
            });
        }
    };

    loadOptionsVinculos = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('vinculos', inputValue, (vinculos) => {                
                callback(vinculos);
            });
        }
    };

    loadOptionsUsuarios = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('usuarios', inputValue, (usuarios) => {                
                callback(usuarios);
            });
        }
    };

    renderComarcas(comarcas) {
        return (
            <div>
                <label>Comarca</label>
                <AsyncSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={ true }
                    openMenuOnClick={ false }
                    value={ comarcas }
                    loadOptions={this.loadOptionsComarcas}
                    onChange={this.handleChangeComarcas}
                    noOptionsMessage={ () => 'Nenhuma comarca encontrada' }
                    placeholder="Buscar Comarca..."
                />
            </div>
        );
    }

    renderSetores(setores) {
        return (
            <div>
                <label>Lotação</label>
                <AsyncSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={ true }
                    openMenuOnClick={ false }
                    value={ setores }
                    loadOptions={this.loadOptionsSetores}
                    onChange={this.handleChangeSetores}
                    noOptionsMessage={ () => 'Nenhuma lotação encontrada' }
                    placeholder="Buscar Lotação..."
                />
            </div>
        );
    }

    renderVinculos(vinculos) {
        return (
            <div>
                <label>Tipo de Vínculo</label>
                <AsyncSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={ true }
                    openMenuOnClick={ false }
                    value={ vinculos }
                    loadOptions={this.loadOptionsVinculos}
                    onChange={this.handleChangeVinculos}
                    noOptionsMessage={ () => 'Nenhum vínculo encontrado' }
                    placeholder="Buscar Vínculo..."
                />
            </div>
        );
    }

    renderUsuarios(usuarios) {
        return (
            <div>
                <label>Usuário</label>
                <AsyncSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={ true }
                    openMenuOnClick={ false }
                    value={ usuarios }
                    loadOptions={this.loadOptionsUsuarios}
                    onChange={this.handleChangeUsuarios}
                    noOptionsMessage={ () => 'Nenhum usuário encontrado' }
                    placeholder="Buscar Usuário..."
                />
            </div>
        );
    }

    render () {
        const { handleSubmit } = this.props;
        const { comarca, lotacao, vinculo, usuario } = this.state;

        return (
            <div>
                <Button color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                    Filtrar dados
                </Button>
                <UncontrolledCollapse toggler="#toggler" style={{ marginBottom: '1rem' }}>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(this.onSubmit)}>
                                <div className="row">
                                    <div className="className col-6">
                                        {this.renderUsuarios(usuario)}
                                    </div>
                                    <div className="className col-3">
                                        <Field
                                            name="data_inicial"
                                            label="Data inicial"
                                            validate={[validDateTime, anoInicial]}
                                            component={renderDateTimeField}
                                        />
                                    </div>
                                    <div className="className col-3">
                                        <Field
                                            name="data_final"
                                            label="Data final"
                                            validate={[validDateTime, anoInicial]}
                                            component={renderDateTimeField}
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '1rem' }}>
                                    <div className="className col-4">
                                        {this.renderVinculos(vinculo)}
                                    </div>
                                    <div className="className col-4">
                                        {this.renderComarcas(comarca)}
                                    </div>
                                    <div className="className col-4">
                                        {this.renderSetores(lotacao)}
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '1rem' }}>
                                    <div className="col-2">
                                        <button
                                            type="button"
                                            onClick={this.clearForm}
                                            className="btn btn-sm btn-dark mr-1">Limpar</button>
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-primary">Filtrar</button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </div>
        );
    }
}

export default compose(
    connect(null, actions),
    reduxForm({ form: 'formEleicaoBusca', initialValues: { usuario: '', vinculo: '', comarca: '', lotacao: '', data_inicial: '', data_final: ''} })
)(EleicaoBusca);