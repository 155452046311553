import axios from 'axios';
import { convertToSlug } from '../utils/renderField';
import { ROOT_URL, ROOT_JSON } from '../utils/Global';
import { isUndefined } from 'util';
import {
    AUTH_USER,
    AUTH_ERROR,
    IP_USUARIO,
    UNAUTH_USER,
    CREATE_LIBERACAO,
    EDIT_LIBERACAO,
    GET_LIBERACAO,
    GET_LIBERACOES_USUARIO,
    FETCH_LIBERACOES,
    FETCH_ELEICOES_USUARIO,
    CREATE_ELEICAO,
    EDIT_ELEICAO,
    FINALIZA_ELEICAO,
    ELEICAO_INICIADA,
    GET_ELEICAO,
    GET_ELEICAO_SIMULADA,
    VOTAR_ELEICAO,
    FETCH_ELEICOES,
    FETCH_ELEICOES_LIBERADAS,
    FETCH_ELEICAO_CANDIDATOS,
    BUSCA_USUARIO_NOME,
    BUSCA_CARGO_NOME,
    BUSCA_SETOR_NOME,
    BUSCA_COMARCA_NOME,
    BUSCA_VINCULO_NOME,
    BUSCA_COMBO_INCLUSAO,
    TOGGLE_MODAL_ELEICAO,
    TOGGLE_MODAL_VOTAR_ELEICAO,
    TOGGLE_MODAL_PRINT,
    TOGGLE_MODAL_APURACAO,
    SET_CANDIDATOS_TRATADOS,
    SEND_FILE_CDN,
    DELETE_FILE_CDN,
    SET_TIPO_RELATORIO,
    SET_MULTIPLO_DETALHADO,
    GET_ELEICAO_ZERESIMA,
    GET_ELEICAO_VOTACAO,
    SET_LIBERACAO_ID,
    GET_FORM_CANDIDATOS
} from "./types";

////////////////////////////
////////AUTH ACTIONS////////
////////////////////////////
export const login = (formProps, callback) => async dispatch => {
    try {
        const response = await axios.post(`${ROOT_URL}/login`, formProps);

        dispatch({type: AUTH_USER, payload: response.data});

        localStorage.setItem('token', response.data);
        localStorage.setItem('timeactive', Date.now() + 1*1000*60);

        callback();
    } catch (error) {
        dispatch({ type: AUTH_ERROR, payload: 'Usuário e/ou senha inválidos'} );
    }
};

export const logout = () => {
    return dispatch => {
        localStorage.removeItem('token');
        localStorage.removeItem('timeactive');

        dispatch({ type: UNAUTH_USER });
    }
};

export const setIPUsuario = (ipUsuario) => async dispatch => {
    dispatch({type: IP_USUARIO, payload: ipUsuario});
};

////////////////////////////
/////LIBERACOES ACTIONS/////
////////////////////////////
export const fetchLiberaoes = () => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/liberacao`);

    dispatch({type: FETCH_LIBERACOES, payload: response.data});
};

export const filterLiberaoes = (params) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/liberacao?${params}`);

    dispatch({type: FETCH_LIBERACOES, payload: response.data});
};

export const createLiberacao = (liberacao, callback) => async dispatch => {
    try {
        const response = await axios.post(`${ROOT_URL}/liberacao`, liberacao);

        dispatch({type: CREATE_LIBERACAO, payload: response.data});
        
        callback(response.data);
    } catch (error) {
        console.log('Erro ao tentar salvar', error);
    }
};

export const editLiberacao = (id, liberacao, callback) => async dispatch => {
    try {
        const response = await axios.put(`${ROOT_URL}/liberacao/${id}`, liberacao);

        dispatch({type: EDIT_LIBERACAO, payload: response.data});

        callback(response.data);
    } catch (error) {
        console.log('Erro ao tentar salvar', error);
    }
};

export const getLiberacao = (idLiberacao, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/liberacao/${idLiberacao}`);

        dispatch({type: GET_LIBERACAO, payload: response.data});

        callback(response.data);
    } catch (error) {
        console.log('Erro ao tentar buscar a liberação', error);
    }
};

export const getLiberacoesUsuario = (callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/liberacoesusuario`);

        const liberacoes = response.data.map(liberacao => {
            return { label: liberacao.descricao, value: liberacao.id, data_inicial: liberacao.data_inicial, data_final: liberacao.data_final };
        });

        dispatch({type: GET_LIBERACOES_USUARIO, payload: liberacoes});

        callback(liberacoes);

    } catch (error) {
        console.log('Erro ao tentar buscar a liberação', error);
    }
};

export const filterLiberacoesUsuario = (params) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicao?${params}`);

    dispatch({type: FETCH_ELEICOES, payload: response});
};

export const setLiberacaoId = (liberacaoId) => async dispatch => {

    dispatch({type: SET_LIBERACAO_ID, payload: liberacaoId});
};

////////////////////////////
//////ELEICOES ACTIONS//////
////////////////////////////
export const fetchEleicoes = (callback) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicao`);

    dispatch({type: FETCH_ELEICOES, payload: response});

    callback(response);
};

export const filterEleicoes = (params) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicao?${params}`);
    
    dispatch({type: FETCH_ELEICOES, payload: response});
};

export const fetchEleicoesUsuario = (callback) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicoesusuario`);

    dispatch({type: FETCH_ELEICOES_USUARIO, payload: response.data});

    callback(response.data);
};

export const filterEleicoesUsuario = (status, params) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicoesusuario?${params}`);
    
    const eleicoes = response.data.filter((eleicao) => {
        if(status)
            return eleicao.status === status
        else
            return eleicao
    })

    dispatch({type: FETCH_ELEICOES_USUARIO, payload: eleicoes});

};

export const fetchEleicoesLiberadas = (callback) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicao/liberadas/usuario`);
        
    dispatch({type: FETCH_ELEICOES_LIBERADAS, payload: response});

    callback(response);
};

export const fetchEleicaoCandidatosOriginal = (eleicaoId, callback) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicao/candidatos/original/${eleicaoId}`);

    dispatch({type: FETCH_ELEICAO_CANDIDATOS, payload: response.data});

    callback(response.data);
};

export const fetchEleicaoCandidatosSimulada = (eleicaoId, callback) => async dispatch => {
    const response = await axios.get(`${ROOT_URL}/eleicao/candidatos/simulada/${eleicaoId}`);

    dispatch({type: FETCH_ELEICAO_CANDIDATOS, payload: response.data});

    callback(response.data);
};

export const createEleicao = (eleicao, callback) => async dispatch => {
    try {
        const response = await axios.post(`${ROOT_URL}/eleicao`, eleicao);

        dispatch({type: CREATE_ELEICAO, payload: response.data});

        callback(response.data);
    } catch (error) {
        console.log('Erro ao tentar salvar', error);
        callback(error);
    }
};

export const editEleicao = (eleicaoId, eleicao, callback) => async dispatch => {
    try {
        const response = await axios.put(`${ROOT_URL}/eleicao/${eleicaoId}`, eleicao);

        dispatch({type: EDIT_ELEICAO, payload: response.data});

        callback(response.data);
    } catch (error) {
        console.log('Erro ao tentar salvar', error);
    }
};

export const finalizaEleicao = (eleicao, callback) => async dispatch => {
    try {
        const response = await axios.post(`${ROOT_URL}/eleicao/finaliza`, eleicao);

        dispatch({type: FINALIZA_ELEICAO, payload: response.data});

        callback(response.data);
    } catch (error) {
        console.log('Erro ao tentar finalizar', error);
    }
};

export const eleicaoIniciada = (eleicaoId, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/eleicao/iniciada/${eleicaoId}`);

        dispatch({type: ELEICAO_INICIADA, payload: response.data.iniciada});

        callback(response.data.iniciada);
    } catch (error) {
        console.log('Erro ao tentar buscar a eleição', error);
    }
};


export const votarEleicao = (eleicaoId, votos, callback) => async dispatch => {
    try {
        const response = await axios.put(`${ROOT_URL}/eleicao/votar/${eleicaoId}`, votos);

        dispatch({type: VOTAR_ELEICAO, payload: response.data});

        callback(response.data);
    } catch (error) {
        console.log('Erro ao tentar votar', error);
    }
};

export const getEleicao = (eleicaoId, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/eleicao/${eleicaoId}`);

        dispatch({type: GET_ELEICAO, payload: response.data});

        callback();
    } catch (error) {
        console.log('Erro ao tentar buscar a eleição', error);
    }
};

export const getEleicaoSimulada = (eleicaoId, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/eleicao/simulada/${eleicaoId}`);

        dispatch({type: GET_ELEICAO_SIMULADA, payload: response.data});

        callback();
    } catch (error) {
        console.log('Erro ao tentar buscar a eleição', error);
    }
};

////////////////////////////
///////FORMULARIOS ACTIONS///////
////////////////////////////

export const getFormCandidatos = (candidato) => async dispatch => {
    dispatch({type: GET_FORM_CANDIDATOS, payload: candidato});
};

////////////////////////////
///////BUSCAS ACTIONS///////
////////////////////////////
export const buscaComboInclusoes = (field, value, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/busca/inclusao/eleicao?field=${field}&value=${value}`);

        const inclusoes = response.data
            .filter(inclusao => convertToSlug(inclusao.label).indexOf(convertToSlug(value)) !== -1 )
            .map(inclusao => {
                return { label: inclusao.label, value: inclusao.value };
            });

        dispatch({type: BUSCA_COMBO_INCLUSAO, payload: inclusoes});

        callback(inclusoes);
    } catch (error) {
        callback([]);
    }
};

export const buscaUsuariosNome = (nome, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/busca/usuarios?field=nome&value=${nome}`);

        const usuarios = response.data.map(usuario => {
            return { label: usuario.nome, value: usuario.uid };
        });

        dispatch({type: BUSCA_USUARIO_NOME, payload: usuarios});

        callback(usuarios);
    } catch (error) {
        callback([]);
    }
};

export const buscaCargosNome = (nome, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/busca/cargos?field=nome&value=${nome}`);

        const cargos = Object.entries(response.data).map(([key,value])=>{
            return { label: value.toString(), value: key };
        })

        dispatch({type: BUSCA_CARGO_NOME, payload: cargos});

        callback(cargos);
    } catch (error) {
        callback([]);
    }
};

export const buscaComarcasNome = (nome, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/busca/comarcas?field=nome&value=${nome}`);

        const comarcas = Object.entries(response.data).map(([key,value])=>{
            return { label: value.toString(), value: key };
        })

        dispatch({type: BUSCA_COMARCA_NOME, payload: comarcas});

        callback(comarcas);
    } catch (error) {
        callback([]);
    }
};

export const buscaSetoresComarca = (vsetores, callback) => async dispatch => {
    try {
        const response = (vsetores.comarcas.length === 0) ? await axios.get(`${ROOT_URL}/busca/setores?field=nome&value=${encodeURIComponent(vsetores.value)}`) : await axios.get(`${ROOT_URL}/busca/setores-comarca?field=nome&value=${vsetores.value}&comarcas=${vsetores.comarcas}`);
        
        if(isUndefined(vsetores.value))
        {
            const setores = Object.entries(response.data).map(([key,value])=>{
                return { label: value.toString(), value: key };
            })

            dispatch({type: BUSCA_SETOR_NOME, payload: setores});

            callback(setores);
        } else {
            const setores = Object.entries(response.data)
            .filter(([key,value]) => convertToSlug(value).indexOf(convertToSlug(vsetores.value)) !== -1 )
            .map(([key,value]) => {
                return { label: value.toString(), value: key };
            });

            dispatch({type: BUSCA_SETOR_NOME, payload: setores});

            callback(setores);
        }
    } catch (error) {
        callback([]);
    }
};

// MOCK JSON-SERVER
export const buscaSetoresNome = (vsetores, callback) => async dispatch => {
    try {
        const comarcas = vsetores.comarcas.map(comarca => `comarca_id=${encodeURIComponent(comarca)}`).join("&");

        const response = await axios.get(`${ROOT_JSON}/setores/?${comarcas}&nome_like=${vsetores.value}`);

        const setores = response.data.map(comarca => {
            return { label: comarca.nome, value: comarca.id };
        })

        dispatch({type: BUSCA_SETOR_NOME, payload: setores});

        callback(setores);
    } catch (error) {
        callback([]);
    }
};

export const buscaVinculosNome = (nome, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/busca/vinculos?field=nome&value=${nome}`);

        const vinculos = Object.entries(response.data).map(([key,value])=>{
            return { label: value.toString(), value: key };
        })

        dispatch({type: BUSCA_VINCULO_NOME, payload: vinculos});

        callback(vinculos);
    } catch (error) {
        callback([]);
    }
};

////////////////////////////
////////MODAL ACTIONS///////
////////////////////////////
export const toggleModalEleicao = (showModal) => async dispatch => {
    dispatch({type: TOGGLE_MODAL_ELEICAO, payload: showModal});
};

export const toggleModalVotarEleicao = (showModalVotarEleicao) => async dispatch => {
    dispatch({type: TOGGLE_MODAL_VOTAR_ELEICAO, payload: showModalVotarEleicao});
};

export const toggleModalPrint = (showModalPrint) => async dispatch => {
    dispatch({type: TOGGLE_MODAL_PRINT, payload: showModalPrint});
};

export const toggleModalApuracao = (showModalApuracao) => async dispatch => {
    dispatch({type: TOGGLE_MODAL_APURACAO, payload: showModalApuracao});
};

export const tratarCandidatos = (eleicao, qtdaSelecaoCandidato) => async dispatch => {

    let candidatos = [];
    
    candidatos = eleicao.candidatos
        .filter(candidato => candidato.nome !== "VOTO EM BRANCO")
        .map(candidato => ({ ...candidato }));

    if(!qtdaSelecaoCandidato) {
        dispatch({type: SET_CANDIDATOS_TRATADOS, payload: candidatos});
        return;
    };

    let arr = Array
        .apply(null, { length: qtdaSelecaoCandidato })
        .map((_, i) => 
            ({ multiplo: i + 1, votos: 0 })
        );

    candidatos = candidatos.map(candidato => {
        let arrC = Array.from(arr);

        !!candidato.multiplosVotos &&
            candidato.multiplosVotos.map(voto => 
                arrC.splice(voto.multiplo - 1, 1, voto)
            );
        
        candidato.multiplosVotos = arrC;
        return candidato;
    });

    dispatch({type: SET_CANDIDATOS_TRATADOS, payload: candidatos});
};

////////////////////////////
////////RELATORIOS ACTIONS///////
////////////////////////////
export const toggleSetTipoRelatorio = (tipoRelatorio) => async dispatch => {
    dispatch({type: SET_TIPO_RELATORIO, payload: tipoRelatorio});
};

export const toggleDetalharMultiplo = (isMultilploDetalhado) => async dispatch => {
    dispatch({type: SET_MULTIPLO_DETALHADO, payload: isMultilploDetalhado});
};

export const getZeresima = (eleicaoId, simulada, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/relatorio/zeresima/${simulada}/${eleicaoId}`);

        dispatch({type: GET_ELEICAO_ZERESIMA, payload: response.data});

        callback(response);
    } catch (error) {
        console.log('Erro ao tentar buscar o relatório de zerésima', error);
    }
};

export const getVotacao = (eleicaoId, simulada, callback) => async dispatch => {
    try {
        const response = await axios.get(`${ROOT_URL}/relatorio/votacao/${simulada}/${eleicaoId}`);

        dispatch({type: GET_ELEICAO_VOTACAO, payload: response.data});

        callback(response);
    } catch (error) {
        console.log('Erro ao tentar buscar o relatório de votação', error);
    }
};

////////////////////////////
////////CDN ACTIONS///////
////////////////////////////
export const sendFileCdn = (arquivo_data) => async dispatch => {
    try {
        const response = await axios.post(`${ROOT_URL}/cdn/upload`, arquivo_data);

        dispatch({type: SEND_FILE_CDN, payload: response});

    } catch (error) {
        console.log('Erro ao tentar enviar arquivo', error);
    }
};

export const deleteFileCdn = (arquivo_nome) => async dispatch => {
    try {
        const response = await axios.post(`${ROOT_URL}/cdn/delete`, arquivo_nome);

        dispatch({type: DELETE_FILE_CDN, payload: response});

    } catch (error) {
        console.log('Erro ao tentar deletar', error);
    }
};