import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

export default class CandidatosTable extends Component {
    render() {        
        if (this.props.candidatos.length === 0) {
            return '';
        }

        return (
            <table className="mt-2 table table-sm table-striped">
                <thead>
                <tr>
                    <th>Foto</th>
                    <th>Nome</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    this.props.candidatos.map((candidato, index) => {
                        return(
                            <tr key={index}>
                                <td><img width="40" src={`https://cdn-0.mpma.mp.br/evoto/${candidato.foto}`} alt="" className="img-responsive img-thumbnail" /></td>
                                <td>{candidato.nome}</td>
                                <td>
                                { this.props.candidatos.length > 1 ? <Button type="button" className="btn-danger btn-sm pull-right" onClick={() => this.props.removeCandidato(index, candidato.foto)}>
                                                                        <FontAwesomeIcon icon="minus-circle" />
                                                                    </Button> : null }
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        );
    }
}