import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../actions";
import logo from "../../assets/img/e-Voto.svg";
import getUserIP from "../../utils/GetIP";

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

class MemberHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.toggle = this.toggle.bind(this);
        this.logOutClickHandler = this.logOutClickHandler.bind(this);
    }

    componentDidMount() {
        getUserIP(ipUsuario => {
            this.props.setIPUsuario(ipUsuario);
        });
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    logOutClickHandler(event) {
        event.preventDefault();

        this.props.logout();
    }

    render() {
        return (
            <Navbar expand="md" className="navbar-dark bg-dark">
                <Link className="navbar-brand" to="/">
                    <img src={logo} className="e-voto-logo" alt="e-Voto" />
                </Link>
                <NavbarToggler className="mr-auto" onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-2 ml-md-0" navbar>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Eleiçōes
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} to="/eleicaoliberacao">
                                    Manter Eleiçōes
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>

                <div className="d-flex align-items-center justify-content-between">
                    <span className="navbar-text p-2">
                        {this.props.loggedUser.nome.split(/[ ,]+/)[0]}
                    </span>
                    <button
                        className="btn btn-outline-danger"
                        onClick={this.logOutClickHandler}
                    >
                        Sair
                    </button>
                </div>
            </Navbar>
        );
    }
}
function mapStateToProps(state) {
    return { loggedUser: state.auth.loggedUser };
}

export default connect(mapStateToProps, actions)(MemberHeader);
