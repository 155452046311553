import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import WizardFormEdit from './form/WizardFormEdit';

class ModalEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalEdit: false,
            candidatos : []
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            showModalEdit: !this.state.showModalEdit
        });
    }

    componentWillReceiveProps(props) {
        this.setState({ showModalEdit: props.showModalEdit })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.showModalEdit} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>Alterar Eleição</ModalHeader>
                    <ModalBody>
                        <WizardFormEdit eleicao={this.props.eleicao} candidatos={this.state.candidatos} closeModal={this.toggle} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { eleicao: state.eleicao.eleicao };
}

export default connect(mapStateToProps, null)(ModalEdit);