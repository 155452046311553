import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { reduxForm, Field } from 'redux-form';
import * as actions from '../../actions';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import { validDateTime, anoInicial, renderField, renderDateTimeField } from '../../utils/renderField';
import moment from 'moment';

class LiberacaoBusca extends Component {

    constructor(props) {
        super(props);

        this.clearForm = this.clearForm.bind(this);
    }
    onSubmit = formProps => {

        if ((formProps.data_inicial && formProps.data_final) && (formProps.data_inicial >= formProps.data_final)) {
            toastr.error('Atenção', 'A data e hora final deve ser maior que a data e hora inicial');

            return;
        }

        let params = 'p=' + true

        if (formProps.status) {
            params += '&status=' + formProps.status;
        }

        if (formProps.usuario) {
            params += '&usuario=' + formProps.usuario;
        }

        if (formProps.cargo) {
            params += '&cargo=' + formProps.cargo;
        }

        if (formProps.comarca) {
            params += '&comarca=' + formProps.comarca;
        }

        if (formProps.lotacao) {
            params += '&lotacao=' + formProps.lotacao;
        }

        if (formProps.data_inicial) {
            params += '&data_inicial=' + moment(formProps.data_inicial).format('YYYY-MM-DDTHH:mm');
        }

        if (formProps.data_final) {
            params += '&data_final=' + moment(formProps.data_final).format('YYYY-MM-DDTHH:mm');
        }

        this.props.filterLiberaoes(params);
    };

    clearForm() {
        const initData = {
            usuario: '',
            data_inicial: '',
            data_final: ''
        };

        this.props.initialize(initData);

        this.props.filterLiberaoes('');
    }

    render () {
        const { handleSubmit } = this.props;

        return (
            <div>
                <Button color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                    Filtrar dados
                </Button>
                <UncontrolledCollapse toggler="#toggler" style={{ marginBottom: '1rem' }}>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(this.onSubmit)}>
                                <div className="row">
                                    <div className="col-4">
                                        <Field
                                            name="usuario"
                                            type="text"
                                            label="Usuário"
                                            component={renderField}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Field
                                            name="data_inicial"
                                            label="Data inicial"
                                            validate={[validDateTime, anoInicial]}
                                            component={renderDateTimeField}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Field
                                            name="data_final"
                                            label="Data final"
                                            validate={[validDateTime, anoInicial]}
                                            component={renderDateTimeField}
                                        />
                                    </div>
                                    <div className="col-2" style={{paddingTop: '33px'}}>
                                        <button
                                            type="button"
                                            onClick={this.clearForm}
                                            className="btn btn-sm btn-dark mr-1">Limpar</button>
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-primary">Filtrar</button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </div>
        );
    }
}

export default compose(
    connect(null, actions),
    reduxForm({ form: 'formLiberacaoBusca', initialValues: { usuario: '', data_inicial: '', data_final: ''} })
)(LiberacaoBusca);