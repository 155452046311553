import React from 'react';
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalEditForm from './ModalEditForm';

class ModalEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalEdit: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            showModalEdit: !this.state.showModalEdit
        });
    }

    componentWillReceiveProps(props) {
        this.setState({ showModalEdit: props.showModalEdit })
    }

    render() {
        const { liberacao } = this.props;
        const { showModalEdit } = this.state;

        return (
            <div>
                <Modal isOpen={showModalEdit} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>Alterar Liberação</ModalHeader>
                    <ModalBody>
                        <ModalEditForm liberacao={liberacao} closeModal={this.toggle} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { liberacao: state.liberacao.liberacao };
}

export default connect(mapStateToProps, null)(ModalEdit);