import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";
import brasao from "../../assets/img/brasao-maranhao.png";
import PrintTemplate from "./PrintTemplate";
import * as actions from "../../actions";

class ModalPrint extends Component {
    pdfExportComponent;

    constructor(props) {
        super(props);

        this.togglePrint = this.togglePrint.bind(this);
    }

    togglePrint() {
        this.props.toggleModalPrint(!this.props.showModalPrint);
        this.props.toggleModalEleicao(false);
    }

    toggleDetalharMultiplo = (isMultiploDetalhado) => {
        this.props.toggleDetalharMultiplo(isMultiploDetalhado);
    }

    exportPDFWithComponent = () => {
        this.pdfExportComponent.save();
    };

    renderCandidatosVotacao = (candidatosTratados, isMultiploDetalhado) => {
        let candidatoDescricaoDetalhado = (candidato) => (
            <div className="relatorio-votacao-candidato">
                <div className="row">
                    <div className="col-12">
                        {candidato.nome}
                    </div>
                </div>
                {
                    candidato.multiplosVotos.map(({ multiplo }, index) => {
                        return (
                            <div className="row" key={`candidato-${candidato.nome}-desc-${index}`}>
                                <div className="col-lg-4 col-md-11 offset-lg-8 offset-1">
                                    Votos em {multiplo}
                                </div>
                            </div>
                        )
                    })
                }
                <div className="row">
                    <div className="col-lg-4 col-md-11 offset-lg-8 offset-1">
                        Total
                    </div>
                </div>
            </div>
        );

        let candidatoVotosDetalhado = (candidato) => (
            <div className="relatorio-votacao-votos">
                <div className="col-12">&nbsp;</div>
                {
                    candidato.multiplosVotos.map(({ votos }, index) => {
                        return (
                            <div key={`candidato-${candidato.nome}-voto-${index}`} className="col-12">
                                {votos}
                            </div>
                        )
                    })
                }
                <div className="col-12">
                    {
                        !!candidato.multiplosVotos &&
                        candidato.multiplosVotos
                            .reduce((a, b) =>
                                (a + b.votos),
                                0
                            )
                    }
                </div>
            </div>
        );

        return candidatosTratados
            .filter(
                candidato =>
                    candidato.nome !==
                    "VOTO EM BRANCO",
            )
            .map((candidato, index) => {
                return (
                    <tr
                        className="d-flex linha-candidato"
                        key={index}
                    >
                        <td className="col-2">
                            <img
                                width="30"
                                src={`https://cdn-0.mpma.mp.br/evoto/${candidato.foto}`}
                                alt=""
                                className="img-responsive img-thumbnail"
                            />
                        </td>
                        <td className="col-8">
                            {isMultiploDetalhado
                                ? candidatoDescricaoDetalhado(candidato)
                                : candidato.nome
                            }
                        </td>
                        <td className="col-2">
                            {isMultiploDetalhado
                                ? candidatoVotosDetalhado(candidato)
                                : !!candidato.multiplosVotos &&
                                candidato.multiplosVotos
                                    .reduce((a, b) =>
                                        (a + b.votos),
                                        0
                                    )
                            }
                        </td>
                    </tr>
                );
            });
    }

    renderCandidatosZeresima = (candidatosTratados) => {
        return candidatosTratados
            .filter(
                candidato =>
                    candidato.nome !==
                    "VOTO EM BRANCO",
            )
            .map((candidato, index) => {
                return (
                    <tr
                        className="d-flex"
                        key={index}
                    >
                        <td className="col-2">
                            <img
                                width="30"
                                src={`https://cdn-0.mpma.mp.br/evoto/${candidato.foto}`}
                                alt=""
                                className="img-responsive img-thumbnail"
                            />
                        </td>
                        <td className="col-8">
                            {candidato.nome}
                        </td>
                        <td className="col-2">
                            {candidato.votos}
                        </td>
                    </tr>
                );
            })
    }

    render() {
        const {
            eleicao,
            qtdaEleitores,
            totalVotos,
            totalVotantes,
            totalBrancos,
            showModalPrint,
            descEleicao,
            loggedUser,
            modalTipo,
            dataInicial,
            dataFinal,
            isMultiploDetalhado,
            candidatosTratados,
        } = this.props;

        if (!eleicao.candidatos || !candidatosTratados.length) {
            return "";
        }

        const nComputados = (qtdaEleitores - totalVotantes);

        if (!!candidatosTratados.length) {
            // Exibição do detalhamento do votos em multiplos não responde bem ao exportamento para PDF,
            // setando o total de linhas que contém cada candidato para ajustar no CSS da exportação.
            if (candidatosTratados[0].multiplosVotos) {
                document.documentElement.style.setProperty(
                    '--linhas',
                    (candidatosTratados[0].multiplosVotos.length + 2)
                );
            }

        }

        return (
            <Modal
                className="modal-evoto"
                isOpen={showModalPrint}
                toggle={this.togglePrint}
                size="lg"
            >
                <ModalHeader toggle={this.togglePrint}>
                    {descEleicao}
                </ModalHeader>

                <ModalBody>
                    {/* VISUALIZADO */}
                    <PDFExport
                        pageTemplate={PrintTemplate}
                        creator={loggedUser.nome}
                        fileName={descEleicao}
                        title={descEleicao}
                        ref={component =>
                            (this.pdfExportComponent = component)
                        }
                        margin={{
                            top: 20,
                            left: 30,
                            right: 30,
                            bottom: 20,
                        }}
                        paperSize="A4"
                    >

                        <div className="view-texto-imprimir">
                            <div className="row">
                                <div className="col-sm text-center">
                                    <div>
                                        <img
                                            src={brasao}
                                            width="70"
                                            alt="Maranhao"
                                        />
                                    </div>
                                    <div className="bold-logo-imprimir">
                                        Estado do Maranhão
                                </div>
                                    <div className="bold-logo-imprimir">
                                        Ministério Público
                                </div>
                                    <div className="bold-title-imprimir">
                                        {"Relatório de " +
                                            (modalTipo === "votacao"
                                                ? "Votação"
                                                : "Zerésima")}
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="row">
                                <div className="col-sm text-left">
                                    <span>
                                        Emissão:{" "}
                                        {moment().format("DD/MM/YYYY HH:mm")} por{" "}
                                        {loggedUser.nome}
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm text-left">
                                    <span>
                                        Período de Votação{" "}
                                        {moment(dataInicial).format(
                                            "DD/MM/YYYY HH:mm",
                                        )}{" "}
                                    a{" "}
                                        {moment(dataFinal).format(
                                            "DD/MM/YYYY HH:mm",
                                        )}{" "}
                                    </span>
                                </div>
                            </div>

                            {modalTipo === "votacao" && (
                                <div className="row">
                                    <div className="col-12">
                                        <small>{descEleicao}</small>
                                    </div>
                                    <div className="col-4">
                                        <small>
                                            Eleitores Aptos: {qtdaEleitores}
                                        </small>
                                    </div>
                                    <div className="col-4">
                                        <small>Votantes: {totalVotantes}</small>
                                    </div>
                                    <div className="col-4">
                                        <small>
                                            Votos Não Computados:{" "}
                                            {nComputados < 0 ? 0 : nComputados}
                                        </small>
                                    </div>
                                </div>
                            )}

                            {modalTipo === "zeresima" && (
                                <div className="row">
                                    <div className="col-7">
                                        <small>{descEleicao}</small>
                                    </div>
                                    <div className="col-2">
                                        <small>Votos Brancos: {totalBrancos || 0}</small>
                                    </div>
                                    <div className="col-3">
                                        <small>Votos Apurados: {nComputados || 0}</small>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="card border-0 mt-3">
                            <div className="card-body m-0 p-0">
                                <h5 className="card-title">Contagem de Votos</h5>
                                <table className="mt-2 table table-sm table-striped">
                                    <thead>
                                        <tr className="d-flex">
                                            <th className="col-10">Candidato</th>
                                            <th className="col-2">Votos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalTipo === "votacao"
                                            && this.renderCandidatosVotacao(candidatosTratados, isMultiploDetalhado)}
                                        {modalTipo === "zeresima"
                                            && this.renderCandidatosZeresima(candidatosTratados)}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {modalTipo === "votacao" && (
                            <table className="table table-sm table-hover">
                                <tbody>
                                    <tr className="d-flex font-weight-bold">
                                        <td className="col-10">Votos Brancos</td>
                                        <td className="col-2">{totalBrancos}</td>
                                    </tr>
                                    <tr className="d-flex font-weight-bold">
                                        <td className="col-10">Total de Votos</td>
                                        <td className="col-2">{totalVotos}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}

                        <hr />

                        {modalTipo === "votacao" && (
                            <div className="card border-0 mt-3">
                                <div className="card-body m-0 p-0">
                                    <h5 className="card-title">Eleitores</h5>
                                    <table className="mt-2 table table-sm table-striped">
                                        <thead>
                                            <tr className="d-flex">
                                                <th className="col-2">Matrícula</th>
                                                <th className="col-7">Nome</th>
                                                <th className="col-3">
                                                    Data e Hora
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {eleicao.eleitores.map(
                                                (eleitor, index) => {
                                                    return (
                                                        <tr
                                                            className="d-flex"
                                                            key={index}
                                                        >
                                                            <td className="col-2">
                                                                {eleitor.matricula}
                                                            </td>
                                                            <td className="col-7">
                                                                {eleitor.nome}
                                                            </td>
                                                            <td className="col-3">
                                                                {moment(
                                                                    eleitor.timecreated,
                                                                ).format(
                                                                    "DD/MM/YYYY HH:mm",
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                    </PDFExport>

                    <div className="row">
                        <div className="col-sm text-center view-texto-imprimir">
                            <div>
                                "{moment().format("YYYY")} - O Ministério
                                Público na indução de políticas públicas"
                            </div>
                            <div>
                                Av. Prof. Carlos Cunha, s/no Calhau – CEP:
                                65076-820 – São Luís/MA (98) 3219-1600
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter style={{ justifyContent: "space-around" }}>
                    {modalTipo === "votacao" && (
                        <FormGroup className="form-inline" check>
                            <Label check for="checkboxDetalhar">Detalhar Múltiplo?&nbsp;</Label>
                            <Input type="checkbox"
                                id="checkboxDetalhar"
                                onChange={(e) => this.toggleDetalharMultiplo(e.target.checked)}
                                checked={isMultiploDetalhado} />{' '}
                        </FormGroup>
                    )}
                    <Button
                        color="primary"
                        onClick={this.exportPDFWithComponent}
                    >
                        Salvar PDF
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        eleicao:
            props.modalTipo === "votacao"
                ? state.relatorio.eleicaoVotacao
                : state.relatorio.eleicaoZeresima,
        showModalPrint: state.modal.showModalPrint,
        showModalEleicao: state.modal.showModalEleicao,
        loggedUser: state.auth.loggedUser,
        simulada: state.relatorio.simulada,
        isMultiploDetalhado: state.relatorio.isMultiploDetalhado,
        candidatosTratados: state.modal.candidatosTratados,
        totalMultiploVotos: state.modal.totalMultiploVotos,
    };
}

export default connect(mapStateToProps, actions)(ModalPrint);
