import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { validDateTime, number, minValue0, maxLength9, anoInicial, maioPalavra46, minLength3, maxLength255, renderField, renderDateTimeField, required } from '../../../../utils/renderField';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap';
import * as actions from '../../../../actions';
import Select from 'react-select';
import moment from 'moment';

const options = [
    { label: 'Único', value: 1 },
    { label: 'Múltiplo', value: 2 }
];

class Detalhes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            liberacoes: this.props.liberacoes || [],
            selecaoCandidato: (this.props.selecao_candidato === 2) ? { label: 'Múltiplo', value: 2 } : { label: 'Único', value: 1 },
            selecao_candidato: this.props.selecao_candidato || 1
        }

        this.onChangeSelecaoCandidato = this.onChangeSelecaoCandidato.bind(this);
    }

    onChangeSelecaoCandidato = (selecaoCandidato) => {        
        this.setState({ selecaoCandidato,  selecao_candidato: selecaoCandidato.value });
    }

    onSubmit = formProps => {

        const { selecao_candidato } = this.state;

        formProps.selecao_candidato = selecao_candidato;
        
        formProps.dataInicial = moment(formProps.data_inicial).format('YYYY-MM-DD HH:mm');
        formProps.dataFinal = moment(formProps.data_final).format('YYYY-MM-DD HH:mm');
        formProps.liberacaoDataInicial = moment(this.props.liberacaoSelect.data_inicial).format('YYYY-MM-DD HH:mm');
        formProps.liberacaoDataFinal = moment(this.props.liberacaoSelect.data_final).format('YYYY-MM-DD HH:mm');
                
        if (formProps.dataInicial < formProps.liberacaoDataInicial) {
            toastr.error('Atenção', 'A data e hora inicial está fora do período da Liberação');

            return;
        }

        if (formProps.dataFinal > formProps.liberacaoDataFinal) {
            toastr.error('Atenção', 'A data e hora final está fora do período da Liberação');

            return;
        }

        if (formProps.dataInicial >= formProps.dataFinal) {
            toastr.error('Atenção', 'A data e hora final deve ser maior que a data e hora inicial');

            return;
        }

        this.props.onSubmit();
    };

    handleChangeLiberacoes = (liberacao) => {
        if(liberacao !== null)
            this.props.setLiberacaoId({label: liberacao.label, value: liberacao.value, data_inicial: liberacao.data_inicial, data_final: liberacao.data_final})
        else
            this.props.setLiberacaoId([])
    };

    componentDidMount() {
        this.props.getLiberacoesUsuario((liberacoes) => {
            this.setState({ liberacoes });
        });
    }

    renderLiberacoes(liberacoes, liberacaoSelect) {
        return (
                <div>
                    <label>Selecionar Liberação<span className="text-danger">* </span>
                    {liberacaoSelect.data_inicial && <small>
                        Período de {moment(liberacaoSelect.data_inicial).format('DD/MM/YYYY HH:mm')} a {moment(liberacaoSelect.data_final).format('DD/MM/YYYY HH:mm')}
                    </small>}
                    </label>
                    <Select
                        name="liberacao_id"
                        placeholder="Selecionar Liberação"
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={liberacaoSelect}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.handleChangeLiberacoes}
                        options={liberacoes}
                    />
                </div>
        );
    }
    
    render() {
        const { handleSubmit, liberacaoSelect } = this.props;
        const { liberacoes, selecao_candidato, selecaoCandidato } = this.state;

        const libId = (liberacaoSelect.value === undefined ? 0 : liberacaoSelect.value)

        return (
            
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                    <div className="col-12">
                        {this.renderLiberacoes(liberacoes, liberacaoSelect)}
                    </div>
                </div>

                {libId > 0 && <div className="row">
                    <div className="col-6">
                        <label>Seleção de candidato<span className="text-danger">*</span></label>
                        <Select
                            value={selecaoCandidato}
                            onChange={this.onChangeSelecaoCandidato}
                            options={options}
                        />
                    </div>
                    { selecao_candidato > 1 && <div className="col-6">
                        <Field
                            name="qtda_selecao_candidato"
                            type="number"
                            component={renderField}
                            label="Quant. máxima de candidatos selecionáveis"
                            validate={[number, minValue0, maxLength9]}
                        />
                    </div> } 
                </div> }

                {libId > 0 && <div className="row">
                    <div className="col-6">
                        <Field
                            name="data_inicial"
                            label="Data inicial"
                            component={renderDateTimeField}
                            required
                            validate={[required, validDateTime, anoInicial]}
                        />
                    </div>
                    <div className="col-6">
                        <Field
                            name="data_final"
                            label="Data final"
                            component={renderDateTimeField}
                            required
                            validate={[required, validDateTime, anoInicial]}
                        />
                    </div>
                </div>}
                

                {libId > 0 && <div className="row">
                    <div className="col-4">
                        <label>Voto branco<span className="text-danger">*</span></label>
                        <Field className="form-control" name="branco" component="select">
                            <option value="1">Sim</option>
                            <option value="2">Não</option>
                        </Field>
                    </div>

                    <div className="col-8">
                        <Field
                            name="descricao"
                            type="text"
                            component={renderField}
                            label="Descrição"
                            required
                            validate={[required, maioPalavra46, minLength3, maxLength255]}
                        />
                    </div>
                </div>}
                

                {libId > 0 && <div className="text-right mt-3">
                    <button type="submit" id={'btn-proximo-detalhes'} className="btn btn-info">
                        <FontAwesomeIcon icon="arrow-right"/> Salvar/Próximo
                    </button>
                    <UncontrolledTooltip placement="right" target={'btn-proximo-detalhes'}>
                        Avançar para Documentos
                    </UncontrolledTooltip>
                </div>}
                
            </form>
        );
    }
}

const selector = formValueSelector('formWizardEleicao')

const mapStateToProps = state => ({
    selecao_candidato: selector(state, 'selecao_candidato'),
    liberacaoSelect: state.liberacao.liberacaoSelect
});

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ 
        form: 'formWizardEleicao',
        initialValues: { selecao_candidato: 1, branco: 1 },
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true
    })
)(Detalhes);