import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import { validDateTime, anoInicial, renderField, renderDateTimeField } from '../../utils/renderField';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import moment from 'moment';
import { toastr } from "react-redux-toastr";
import { reduxForm, Field } from 'redux-form';
import * as actions from '../../actions';

const optionsVotacao = [
    { label: 'Original', value: false },
    { label: 'Simulada', value: true }
];

const optionsDetalharMultiplo = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
];

class BuscaVotacao extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            tipoSelecionado: { 'label': 'Original', 'value': false },
            comarca: null, lotacao: null, vinculo: null, usuario: null,
            simulada: false
        };

        this.clearForm = this.clearForm.bind(this);
    }

    componentDidMount() {
        this.clearForm();
        this.props.toggleSetTipoRelatorio(false);
    }

    onChangeTipoEleicao = (tipoSelecionado) => {
        this.props.toggleSetTipoRelatorio(tipoSelecionado.value);
        this.setState({ tipoSelecionado,  simulada: tipoSelecionado.value });

        let params = 'zeresima='+ false +'&simulada=' + tipoSelecionado.value;
        this.props.filterEleicoes(params);
    }
    
    onChangeDetalharMultiplo = (isMultiploDetalhado) => {
        this.props.toggleDetalharMultiplo(isMultiploDetalhado);
    }

    onSubmit = formProps => {

        const { comarca, lotacao, vinculo, usuario } = this.state;
        
        if ((formProps.data_inicial && formProps.data_final) && (formProps.data_inicial >= formProps.data_final)) {
            toastr.error('Atenção', 'A data e hora final deve ser maior que a data e hora inicial');

            return;
        }

        let params = 'zeresima='+ false +'&simulada=' + this.state.simulada;

        if (formProps.descricao) {
            params += '&descricao=' + formProps.descricao;
        }

        if (formProps.candidato) {
            params += '&candidato=' + formProps.candidato;
        }

        if (usuario !== null) {
            params += '&usuario=' + usuario.value;
        }

        if (vinculo !== null) {
            params += '&vinculo=' + vinculo.value;
        }

        if (comarca !== null) {
            params += '&comarca=' + comarca.value;
        }

        if (lotacao !== null) {
            params += '&lotacao=' + lotacao.value;
        }

        if (formProps.data_inicial) {
            params += '&data_inicial=' + moment(formProps.data_inicial).format('YYYY-MM-DDTHH:mm');
        }

        if (formProps.data_final) {
            params += '&data_final=' + moment(formProps.data_final).format('YYYY-MM-DDTHH:mm');
        }

        this.props.filterEleicoes(params);
    };
    
    clearForm() {
        const initData = {
            simulada: '',
            descricao: '',
            data_inicial: '',
            data_final: ''
        };

        this.props.initialize(initData);
        this.setState({ comarca: null, lotacao: null, vinculo: null, usuario: null });
        this.onChangeTipoEleicao(this.state.tipoSelecionado);
    }

    handleChangeComarcas = (comarca) => {
        this.setState({ comarca });
    }

    handleChangeSetores = (lotacao) => {
        this.setState({ lotacao });
    }

    handleChangeVinculos = (vinculo) => {
        this.setState({ vinculo });
    }

    handleChangeUsuarios = (usuario) => {
        this.setState({ usuario });
    }

    loadOptionsComarcas = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('comarcas', inputValue, (comarcas) => {                
                callback(comarcas);
            });
        }
    };

    loadOptionsSetores = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('setores', inputValue, (setores) => {                
                callback(setores);
            });
        }
    };

    loadOptionsVinculos = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('vinculos', inputValue, (vinculos) => {                
                callback(vinculos);
            });
        }
    };

    loadOptionsUsuarios = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComboInclusoes('usuarios', inputValue, (usuarios) => {                
                callback(usuarios);
            });
        }
    };

    renderComarcas(comarcas) {
        return (
            <div>
                <label>Comarca</label>
                <AsyncSelect
                    isClearable={ true }
                    openMenuOnClick={ false }
                    className="basic-multi-select"
                    value={comarcas}
                    loadOptions={this.loadOptionsComarcas}
                    onChange={this.handleChangeComarcas}
                    noOptionsMessage={ () => 'Nenhuma comarca encontrada' }
                    placeholder="Buscar Comarca..."
                />
            </div>
        );
    }

    renderSetores(setores) {
        return (
            <div>
                <label>Lotação</label>
                <AsyncSelect
                    isClearable={ true }
                    openMenuOnClick={ false }
                    className="basic-multi-select"
                    value={setores}
                    loadOptions={this.loadOptionsSetores}
                    onChange={this.handleChangeSetores}
                    noOptionsMessage={ () => 'Nenhuma lotação encontrada' }
                    placeholder="Buscar Lotação..."
                />
            </div>
        );
    }

    renderVinculos(vinculos) {
        return (
            <div>
                <label>Tipo de Vínculo</label>
                <AsyncSelect
                    isClearable={ true }
                    openMenuOnClick={ false }
                    className="basic-multi-select"
                    value={vinculos}
                    loadOptions={this.loadOptionsVinculos}
                    onChange={this.handleChangeVinculos}
                    noOptionsMessage={ () => 'Nenhum vínculo encontrado' }
                    placeholder="Buscar Vínculo..."
                />
            </div>
        );
    }

    renderUsuarios(usuarios) {
        return (
            <div>
                <label>Usuário</label>
                <AsyncSelect
                    isClearable={ true }
                    openMenuOnClick={ false }
                    className="basic-multi-select"
                    value={usuarios}
                    loadOptions={this.loadOptionsUsuarios}
                    onChange={this.handleChangeUsuarios}
                    noOptionsMessage={ () => 'Nenhum usuário encontrado' }
                    placeholder="Buscar Usuário..."
                />
            </div>
        );
    }

    render () {
        const { handleSubmit, isMultiploDetalhado } = this.props;
        const { comarca, lotacao, vinculo, tipoSelecionado } = this.state;
        
        return (
            <div>
                <Button color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                    Filtrar dados
                </Button>

                <UncontrolledCollapse toggler="#toggler" style={{ marginBottom: '1rem' }}>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(this.onSubmit)}>
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-sm-12">
                                    <Field
                                        name="descricao"
                                        type="text"
                                        label="Descrição da Eleição"
                                        component={renderField}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <label>Tipo de eleição</label>
                                    <Select
                                        key="select-tipo-eleicao"
                                        value={tipoSelecionado}
                                        onChange={this.onChangeTipoEleicao}
                                        options={optionsVotacao}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <label>Detalhar Múltiplos</label>
                                    <Select
                                        key="select-detalhar-multiplo"
                                        value={optionsDetalharMultiplo.filter(option => option.value === isMultiploDetalhado)}
                                        onChange={option => this.onChangeDetalharMultiplo(option.value)}
                                        options={optionsDetalharMultiplo}
                                    />
                                </div>
                            </div>
                                <div className="row" style={{ marginTop: '1rem' }}>
                                    
                                    <div className="className col-md-6">
                                        <Field
                                            name="candidato"
                                            type="text"
                                            label="Candidato da Eleição"
                                            component={renderField}
                                        />
                                    </div>
                                    <div className="className col-md-3">
                                        <Field
                                            name="data_inicial"
                                            label="Data inicial da Eleição"
                                            component={renderDateTimeField}
                                            validate={[validDateTime, anoInicial]}
                                        />
                                    </div>
                                    <div className="className col-md-3">
                                        <Field
                                            name="data_final"
                                            label="Data final da Eleição"
                                            component={renderDateTimeField}
                                            validate={[validDateTime, anoInicial]}
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '1rem' }}>
                                    <div className="className col-md-4">
                                        {this.renderVinculos(vinculo)}
                                    </div>
                                    <div className="className col-md-4">
                                        {this.renderComarcas(comarca)}
                                    </div>
                                    <div className="className col-md-4">
                                        {this.renderSetores(lotacao)}
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '1rem' }}>
                                    <div className="col-md-2">
                                        <button
                                            type="button"
                                            onClick={this.clearForm}
                                            className="btn btn-sm btn-dark mr-1">Limpar</button>
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-primary">Filtrar</button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        isMultiploDetalhado: state.relatorio.isMultiploDetalhado
    };
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ 
        form: 'BuscaVotacao', 
        initialValues: { usuario: '', vinculo: '', comarca: '', lotacao: '', data_inicial: '', data_final: ''} 
    })
)(BuscaVotacao);