import React from "react";
import Moment from "react-moment";
import ReactTable from "react-table";
import { connect } from "react-redux";
import * as actions from "../../actions";
import confirm from "reactstrap-confirm";
import { toastr } from "react-redux-toastr";

import ModalEditEleicao from "./modal/ModalEdit";
import ModalViewEleicao from "./modal/ModalView";
import ModalStartEleicao from "./modal/ModalStart";
import ModalCreateEleicao from "./modal/ModalCreate";
import ModalVoters from "./modal/ModalVoters";
import ModalPrint from "../relatorio/ModalPrint";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import EleicaoUsuarioBusca from "./EleicaoUsuarioBusca";

class EleicaoLiberacaoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalEditleicao: false,
            showModalViewEleicao: false,
            showModalStartEleicao: false,
            showModalZeresima: false,
            showModalVoter: false,
        };

        this.printEleicaoVotacao = this.printEleicaoVotacao.bind(this);
    }

    componentDidMount() {
        this.props.fetchEleicoesUsuario(() => {});
    }

    printEleicaoVotacao(idEleicao, data) {
        this.props.getVotacao(idEleicao, false, (result) => {
            this.setState({
                idEleicao,
                descEleicao: data.descricao,
                data_inicial: data.data_inicial,
                data_final: data.data_final,
                qtda_eleitores: data.qtda_eleitores,
                qtda_selecao_candidato: data.qtda_selecao_candidato,
                total_votos: data.total_votos,
                total_votantes: data.total_votantes,
                branco: data.branco,
                total_brancos: data.total_brancos,
            });
            this.props.tratarCandidatos(result.data.votacao, data.qtda_selecao_candidato);
            this.props.toggleModalPrint(!this.props.showModalPrint);
        });
    }


    addEleicao() {
        this.props.toggleModalEleicao(!this.props.showModalCreateEleicao);
    }

    editEleicao(idEleicao) {
        this.props.getEleicao(idEleicao, () => {
            this.setState({
                showModalEditEleicao: true,
                showModalStartEleicao: false,
                showModalViewEleicao: false,
                showModalVoter: false,
            });
        });
    }

    startEleicao(idEleicao) {
        this.props.getEleicao(idEleicao, () => {
            this.setState({
                showModalEditEleicao: false,
                showModalStartEleicao: true,
                showModalViewEleicao: false,
                showModalVoter: false,
            });
        });
    }

    viewEleicao(idEleicao) {
        this.props.getEleicao(idEleicao, () => {
            this.setState({
                showModalEditEleicao: false,
                showModalStartEleicao: false,
                showModalViewEleicao: true,
                showModalVoter: false,
            });
        });
    }

    voterEleicao(idEleicao) {
        this.props.getEleicao(idEleicao, () => {
            this.setState({
                showModalEditEleicao: false,
                showModalStartEleicao: false,
                showModalViewEleicao: false,
                showModalVoter: true,
            });
        });
    }

    finalizaEleicao = async (idEleicao, data) => {
        try {
            const result = await confirm({
                title: (
                    <React.Fragment>
                        Deseja mesmo{" "}
                        <strong>Finalizar a Eleição #{idEleicao}</strong>!
                    </React.Fragment>
                ),
                message: "Esta ação não poderá ser desfeita",
                confirmText: "Finalizar",
                cancelText: "Cancelar",
                confirmColor: "primary",
                cancelColor: "danger",
            });
            if (result) {
                this.props.finalizaEleicao(
                    { eleicao_id: idEleicao, ip: this.props.ipUsuario },
                    callback => {
                        toastr.success("Atenção", callback);

                        this.props.fetchEleicoesUsuario(() => {
                            this.setState({ isLoading: false });
                            this.printEleicaoVotacao(
                                parseInt(idEleicao, 10),
                                data,
                            );
                        });
                    },
                );
            }
        } catch (error) {
            toastr.error("Atenção", error);
        } finally {
            //this.printEleicaoApuracao(parseInt(idEleicao, 10), data);
        }
    };

    renderAddEleicao() {
        return (
            <React.Fragment>
                <button
                    id={"btn-add"}
                    onClick={() => this.addEleicao()}
                    className="btn btn-success btn-sm ml-1"
                >
                    <FontAwesomeIcon icon="plus-circle" /> Criar nova Eleição
                </button>
                <UncontrolledTooltip placement="right" target={"btn-add"}>
                    Criar nova Eleição
                </UncontrolledTooltip>
            </React.Fragment>
        );
    }

    renderStatusEleicao(status, data) {
        return status === "fechada" || status === "expirada" ? (
            <span className="badge badge-pill badge-danger">{status}</span>
        ) : status === "iniciada" ? (
            <span className="badge badge-pill badge-success">{status}</span>
        ) : status === "a iniciar" ? (
            <span className="badge badge-pill badge-warning">{status}</span>
        ) : !data.incluir ? (
            <span className="badge badge-pill badge-info">{status}</span>
        ) : (   
            <React.Fragment>
                <button
                    id={"btn-start-" + data.id}
                    onClick={() => this.startEleicao(data.id)}
                    className="btn btn-info btn-sm ml-1"
                >
                    {status}
                </button>
                <UncontrolledTooltip
                    placement="right"
                    target={"btn-start-" + data.id}
                >
                    Iniciar Eleição
                </UncontrolledTooltip>
            </React.Fragment>
        );
    }

    renderViewEleicao(idEleicao) {
        return (
            <React.Fragment>
                <button
                    id={"btn-viewe-" + idEleicao}
                    onClick={() => this.viewEleicao(idEleicao)}
                    className="btn btn-info btn-sm"
                >
                    <FontAwesomeIcon icon="eye" />
                </button>
                <UncontrolledTooltip
                    placement="right"
                    target={"btn-viewe-" + idEleicao}
                >
                    Visualizar Eleição
                </UncontrolledTooltip>
            </React.Fragment>
        );
    }

    renderEditEleicao(idEleicao, data) {
        if (
            data.editar &&
            data.total_votos === 0 &&
            data.status !== "expirada" &&
            data.status !== "fechada"
        ) {
            return (
                <React.Fragment>
                    <button
                        id={"btn-edit-" + idEleicao}
                        onClick={() => this.editEleicao(idEleicao)}
                        className="btn btn-warning btn-sm ml-1"
                    >
                        <FontAwesomeIcon icon="edit" />
                    </button>
                    <UncontrolledTooltip
                        placement="right"
                        target={"btn-edit-" + idEleicao}
                    >
                        Editar Eleição
                    </UncontrolledTooltip>
                </React.Fragment>
            );
        }
    }

    renderDelEleicao(idEleicao, data) {
        if (data.finalizar && data.status !== "fechada") {
            return (
                <React.Fragment>
                    <button
                        id={"btn-del-" + idEleicao}
                        onClick={() => this.finalizaEleicao(idEleicao, data)}
                        className="btn btn-danger btn-sm ml-1"
                    >
                        <FontAwesomeIcon icon="minus-circle" />
                    </button>
                    <UncontrolledTooltip
                        placement="right"
                        target={"btn-del-" + idEleicao}
                    >
                        Finalizar Eleição
                    </UncontrolledTooltip>
                </React.Fragment>
            );
        }
    }

    render() {
        const {
            idEleicao,
            descEleicao,
            data_inicial,
            data_final,
            qtda_eleitores,
            qtda_selecao_candidato,
            total_votos,
            total_votantes,
            total_brancos,
        } = this.state;
        const { eleicoes, showModalPrint } = this.props;

        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Manter Eleições</h1>
                    {[2].includes(this.props.loggedUser.perfil) &&
                        this.renderAddEleicao()}
                </div>

                <EleicaoUsuarioBusca />

                <ReactTable
                    data={eleicoes}
                    columns={[
                        {
                            Header: `Eleições`,
                            columns: [
                                {
                                    Header: "#",
                                    accessor: "id",
                                    maxWidth: 40,
                                    Cell: row => <>{row.index + 1}</>,
                                },
                                {
                                    Header: "Data inicial",
                                    accessor: "data_inicial",
                                    resizable: false,
                                    minWidth: 148,
                                    maxWidth: 148,
                                    Cell: row => (
                                        <Moment format="DD/MM/YYYY HH:mm">
                                            {row.value}
                                        </Moment>
                                    ),
                                },
                                {
                                    Header: "Data final",
                                    accessor: "data_final",
                                    resizable: false,
                                    minWidth: 148,
                                    maxWidth: 148,
                                    Cell: row => (
                                        <Moment format="DD/MM/YYYY HH:mm">
                                            {row.value}
                                        </Moment>
                                    ),
                                },
                                {
                                    Header: "Descrição",
                                    accessor: "descricao",
                                },
                                {
                                    Header: "Liberação",
                                    accessor: "liberacao_descricao",
                                },
                                {
                                    Header: "Branco",
                                    accessor: "branco",
                                    className: "text-center",
                                    maxWidth: 80,
                                    Cell: row =>
                                        row.value === 1 ? "Sim" : "Não",
                                },
                                {
                                    Header: "Seleção",
                                    accessor: "selecao_candidato",
                                    className: "text-left",
                                    maxWidth: 80,
                                    Cell: row =>
                                        row.value === 1 ? "Único" : "Múltiplo",
                                },
                                {
                                    Header: "Status",
                                    accessor: "status",
                                    className: "text-center",
                                    maxWidth: 80,
                                    Cell: row =>
                                        this.renderStatusEleicao(
                                            row.value,
                                            row.original,
                                        ),
                                },
                                {
                                    Header: "Porcentagem",
                                    className: "text-center",
                                    accessor: "porcentagem",
                                    Cell: row => (
                                        <>
                                            <React.Fragment>
                                                <small
                                                    onClick={() =>
                                                        this.voterEleicao(
                                                            row.row.id,
                                                        )
                                                    }
                                                    style={{
                                                        float: "left",
                                                        position: "relative",
                                                        left: "40%",
                                                    }}
                                                    className="mt-1 cursor-pointer"
                                                >
                                                    {row.value.total_votados}/
                                                    {row.value
                                                        .total_eleitores || "∞"}
                                                </small>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        backgroundColor:
                                                            "#dadada",
                                                        borderRadius: "2px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: `${row.value.porcentagem}%`,
                                                            height: "100%",
                                                            backgroundColor:
                                                                row.value
                                                                    .porcentagem >
                                                                66
                                                                    ? "#85cc00"
                                                                    : row.value
                                                                          .porcentagem >
                                                                      33
                                                                    ? "#ffbf00"
                                                                    : "#ff2e00",
                                                            borderRadius: "2px",
                                                            transition:
                                                                "all .2s ease-out",
                                                        }}
                                                    ></div>
                                                </div>
                                            </React.Fragment>
                                        </>
                                    ),
                                },
                                {
                                    Header: "",
                                    accessor: "id",
                                    sortable: false,
                                    filterable: false,
                                    className: "text-left",
                                    resizable: false,
                                    minWidth: 120,
                                    maxWidth: 120,
                                    Cell: row => (
                                        <div>
                                            {this.renderViewEleicao(row.value)}
                                            {this.renderEditEleicao(
                                                row.value,
                                                row.original,
                                            )}
                                            {this.renderDelEleicao(
                                                row.value,
                                                row.original,
                                            )}
                                        </div>
                                    ),
                                },
                            ],
                        },
                    ]}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando..."
                    noDataText="Nenhum registro foi encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                    className="-striped -highlight"
                    pageSizeOptions={[15, 30, 50, 100]}
                    defaultPageSize={15}
                    defaultSorted={[
                        {
                            id: "index",
                            desc: false,
                        },
                    ]}
                />

                <ModalEditEleicao
                    showModalEdit={this.state.showModalEditEleicao}
                />
                <ModalStartEleicao
                    showModalStart={this.state.showModalStartEleicao}
                />
                <ModalVoters showModalVoter={this.state.showModalVoter} />
                <ModalViewEleicao showModal={this.state.showModalViewEleicao} />
                <ModalCreateEleicao
                    showmodal={this.props.showModalCreateEleicao}
                />

                <ModalPrint
                    showModalPrint={showModalPrint}
                    modalTipo="votacao"
                    idEleicao={idEleicao}
                    descEleicao={descEleicao}
                    dataInicial={data_inicial}
                    dataFinal={data_final}
                    qtdaEleitores={qtda_eleitores}
                    qtdaSelecaoCandidato={qtda_selecao_candidato}
                    totalVotos={total_votos}
                    totalVotantes={total_votantes}
                    totalBrancos={total_brancos}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        eleicoes: state.liberacao.eleicoes,
        showModalEleicao: state.modal.showModalEleicao,
        showModalPrint: state.modal.showModalPrint,
        loggedUser: state.auth.loggedUser,
        ipUsuario: state.auth.ipUsuario,
    };
}

export default connect(mapStateToProps, actions)(EleicaoLiberacaoPage);
