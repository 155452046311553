export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const UNAUTH_USER = 'unauth_user';
export const IP_USUARIO = 'ip_usuario';

export const FETCH_LIBERACOES = 'fetch_liberacoes';
export const FETCH_ELEICOES_USUARIO = 'fetch_eleicoes_usuario';
export const CREATE_LIBERACAO = 'create_liberacao';
export const EDIT_LIBERACAO = 'edit_liberacao';
export const GET_LIBERACAO = 'get_liberacao';
export const GET_LIBERACOES_USUARIO = 'get_liberacoes_usuario';
export const SET_LIBERACAO_ID = 'set_liberacao_id';

export const FETCH_ELEICOES = 'fetch_eleicoes';
export const FETCH_ELEICOES_LIBERADAS = 'fetch_eleicoes_liberadas';
export const FETCH_ELEICAO_CANDIDATOS = 'fetch_eleicao_candidatos';
export const CREATE_ELEICAO = 'create_eleicao';
export const EDIT_ELEICAO = 'edit_eleicao';
export const FINALIZA_ELEICAO = 'finaliza_eleicao';
export const ELEICAO_INICIADA = 'eleicao_iniciada';

export const GET_ELEICAO = 'get_eleicao';
export const GET_ELEICAO_SIMULADA = 'get_eleicao_simulada';

export const VOTAR_ELEICAO = 'votar_eleicao';

export const BUSCA_USUARIO_NOME = 'busca_usuario_nome';
export const BUSCA_CARGO_NOME = 'busca_cargo_nome';
export const BUSCA_SETOR_NOME = 'busca_setor_nome';
export const BUSCA_COMARCA_NOME = 'busca_comarca_nome';
export const BUSCA_VINCULO_NOME = 'busca_vinculo_nome';
export const BUSCA_COMBO_INCLUSAO = 'busca_combo_inclusao';

export const TOGGLE_MODAL_ELEICAO = 'toggle_modal_eleicao';
export const TOGGLE_MODAL_VOTAR_ELEICAO = 'toggle_modal_votar_eleicao';
export const TOGGLE_MODAL_PRINT = 'toggle_modal_print';
export const TOGGLE_MODAL_APURACAO = 'toggle_modal_apuracao';
export const SET_CANDIDATOS_TRATADOS = 'set_candidatos_tratados';

export const SEND_FILE_CDN = 'send_file_cdn';
export const DELETE_FILE_CDN = 'delete_file_cdn';

export const SET_TIPO_RELATORIO = 'set_tipo_relatorio';
export const SET_MULTIPLO_DETALHADO = 'set_multiplo_detalhado';
export const GET_ELEICAO_ZERESIMA = 'get_eleicao_zeresima';
export const GET_ELEICAO_VOTACAO = 'get_eleicao_votacao';

export const GET_FORM_CANDIDATOS = 'get_form_candidatos';