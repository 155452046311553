import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import * as actions from '../../../../actions';

import Detalhes from './Detalhes';
import Documentos from './Documentos';
import Candidatos from './Candidatos';
import Eleitores from './Eleitores';

class WizardForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitDisabled: false,
            page: 1
        };

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 });
    }

    onSubmit(formProps) {
        this.setState({ submitDisabled: true })

        let liberacao_id = this.props.liberacaoSelect.value;

        let serverData = {
            'liberacao_id': liberacao_id,
            'data_inicial': formProps.dataInicial,
            'data_final': formProps.dataFinal,
            'descricao': formProps.descricao,
            'selecao_candidato': parseInt(formProps.selecao_candidato,10),
            'qtda_selecao_candidato': (parseInt(formProps.selecao_candidato,10) === 2 ? parseInt(formProps.qtda_selecao_candidato, 10) : 0),
            'qtda_eleitores': parseInt(formProps.qtda_eleitores, 10),
            'branco': formProps.branco,
            'candidatos': formProps.candidatos,
            'documentos': formProps.documentos,
            'tipo_eleicao': parseInt(formProps.tipo_eleicao, 10),
            'lib_comarcas': formProps.comarcas,
            'lib_setores': formProps.setores,
            'lib_vinculos': formProps.vinculos,
            'lib_usuarios': formProps.usuarios,
            'ip': this.props.ipUsuario
        };

        this.props.createEleicao(serverData, (data) => {
            if(data.msg) {
                toastr.success('Sucesso', data.msg);
                this.props.closeModal();
                this.props.fetchEleicoesUsuario(() => {
                    this.setState({ submitDisabled: false });
                    this.props.setLiberacaoId([]);
                });
            } else {
                toastr.error(
                    'Erro', 
                    data.response.data,
                    { timeOut: 0 , closeOnToastrClick: false }
                );
                this.setState({ submitDisabled: false });
            }
            
        });
    }

    render() {
        const { page, submitDisabled } = this.state;

        return (
            <div>
                {page === 1 && <Detalhes onSubmit={this.nextPage} />}
                {page === 2 && (
                    <Documentos
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                    />
                )}
                {page === 3 && (
                    <Candidatos
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                    />
                )}
                {page === 4 && (
                    <Eleitores
                        previousPage={this.previousPage}
                        onSubmit={this.onSubmit}
                        submitDisabled={submitDisabled}
                    />
                )}

            </div>
        )
    }
}

function mapStateToProps(state) {
    return { 
        liberacaoSelect: state.liberacao.liberacaoSelect,
        showModalPrint: state.modal.showModalPrint,
        showModalEleicao: state.modal.showModalEleicao,
        ipUsuario: state.auth.ipUsuario
    };
}

export default connect(mapStateToProps, actions)(WizardForm);