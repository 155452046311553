import React from 'react';
import { connect } from 'react-redux';
import WizardModalVotar from './WizardModalVotar';

class WizardVotarVotar extends React.Component {
    constructor(props) {
        super(props);

        this.nextPage = this.nextPage.bind(this);

        this.state = {
            page: parseInt(this.props.eleicaoIndex, 10)
        };
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    render() {

        const { page } = this.state;
        const { simulada, eleicoes } = this.props;
        
        return (
            eleicoes.map((eleicao, index) => {
                if(page === index) {
                    return <WizardModalVotar 
                            key={index} 
                            page={page} 
                            total={eleicoes.length} 
                            eleicaoId={eleicao.id} 
                            eleicaoDesc={eleicao.descricao} 
                            selecaoCandidato={eleicao.selecao_candidato}
                            qtdaSelecaoCandidato={eleicao.qtda_selecao_candidato || eleicao.qtda_candidato}
                            simulada={simulada}
                            nextPage={this.nextPage} 
                            onSubmit={this.onSubmit}
                        /> 
                }
                else return false;
            })
        )
    }
}

function mapStateToProps(state) {
    return { showModalVotarEleicao: state.modal.showModalVotarEleicao };
}

export default connect(mapStateToProps, null)(WizardVotarVotar);