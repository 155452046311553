import { AUTH_USER, AUTH_ERROR, UNAUTH_USER, IP_USUARIO } from '../actions/types';
import jwt_decode from 'jwt-decode';

const INITIAL_STATE = { loggedUser: {}, errorMessage: '', ipUsuario: null };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER:
            let user = jwt_decode(action.payload);

            return { ...state, loggedUser: {
                    token: action.payload,
                    nome: user.nome,
                    uid: user.uid,
                    email: user.email,
                    perfil: user.perfil
                }
            };

            // return { ...state, authenticated: action.payload };
        case UNAUTH_USER:
            return { ...state, loggedUser: {} };
        case AUTH_ERROR:
            return { ...state, errorMessage: action.payload };
        case IP_USUARIO:
            return { ...state, ipUsuario: action.payload };    
        default:
            return state;
    }
}
