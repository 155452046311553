import { SET_TIPO_RELATORIO, GET_ELEICAO_ZERESIMA, GET_ELEICAO_VOTACAO, SET_MULTIPLO_DETALHADO } from '../actions/types';

const INITIAL_STATE = { simulada: false, isMultiploDetalhado: false, eleicaoZeresima: [], eleicaoVotacao: [] };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_TIPO_RELATORIO:
            return { ...state, simulada: action.payload };
        case SET_MULTIPLO_DETALHADO:
            return { ...state, isMultiploDetalhado: action.payload };
        case GET_ELEICAO_ZERESIMA:
            return { ...state, eleicaoZeresima: action.payload.votacao };
        case GET_ELEICAO_VOTACAO:
            return { ...state, eleicaoVotacao: action.payload.votacao };     
        default:
            return state;
    }
}
