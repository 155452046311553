import React from "react";
import Header from "./header/Header";

export default ({ children }) => {
    return (
        <div>
            <Header />
            <div className="container pt-3">{children}</div>
        </div>
    );
};
