import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

import AdminHeader from './AdminHeader';
import UserHeader from './UserHeader';
import MemberHeader from './MemberHeader';
import GuestHeader from './GuestHeader';

class Header extends Component {
    constructor(props) {
        super(props);

        this.logOutClickHandler = this.logOutClickHandler.bind(this);
    }

    logOutClickHandler(event) {
        event.preventDefault();

        this.props.logout();
    }

    render() {
        
        if (this.props.loggedUser.perfil !== undefined) {
            if (this.props.loggedUser.perfil === 1) {
                return <AdminHeader />;
            }

            if (this.props.loggedUser.perfil === 2) {
                return <UserHeader />;
            }

            if (this.props.loggedUser.perfil === 3) {
                return <MemberHeader />;
            }

            if (this.props.loggedUser.perfil === 4) {
                return <GuestHeader sair={true} />;
            }
        }

        return <GuestHeader sair={false} />;
    }
}

function mapStateToProps(state) {
    return { loggedUser: state.auth.loggedUser };
}

export default connect(mapStateToProps, actions)(Header);