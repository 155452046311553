import { BUSCA_USUARIO_NOME, BUSCA_COMARCA_NOME, BUSCA_SETOR_NOME, BUSCA_CARGO_NOME, BUSCA_VINCULO_NOME, BUSCA_COMBO_INCLUSAO } from '../actions/types';

const INITIAL_STATE = { usuarios: [], comarcas: [], setores: [], cargos: [], vinculos: [], inclusoes: [] };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case BUSCA_USUARIO_NOME:
            return { ...state, usuarios: action.payload.data };
        case BUSCA_COMARCA_NOME:
            return { ...state, comarcas: action.payload.data };
        case BUSCA_SETOR_NOME:
            return { ...state, setores: action.payload.data };
        case BUSCA_CARGO_NOME:
            return { ...state, cargos: action.payload.data };
        case BUSCA_VINCULO_NOME:
            return { ...state, vinculos: action.payload.data };  
        case BUSCA_COMBO_INCLUSAO:
            return { ...state, inclusoes: action.payload.data }; 
        default:
            return state;
    }
}
