import React from 'react';
import { compose } from 'redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { reduxForm } from 'redux-form';
import WizardFormVotar from './form/WizardFormVotar';


class ModalWizardVotar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this)
    }
    
    toggle() {
        this.props.toggleModalVotarEleicao(!this.props.showModalVotarEleicao);

        setTimeout(() => {
            this.props.fetchEleicoesLiberadas(() => {});
        }, 100);
    }

    render() { 
        const eleicoes = (this.props.simulada === 'true' ? this.props.eleicoesSimuladas : this.props.eleicoesOriginais)
        
        return (
            <div>
                <Modal className="modal-evoto" isOpen={this.props.showModalVotarEleicao} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>Votação {this.props.simulada === 'true' && 'SIMULADA' }</ModalHeader>
                    <ModalBody>
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <WizardFormVotar 
                                eleicaoId={this.props.eleicaoId} 
                                simulada={this.props.simulada} 
                                eleicaoIndex={this.props.eleicaoIndex} 
                                eleicoes={eleicoes} 
                                closeModal={this.toggle}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { showModalVotarEleicao: state.modal.showModalVotarEleicao };
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ form: 'votar' })
)(ModalWizardVotar);