import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import {
    renderField,
    number,
    minValue0,
    maxLength9,
} from "../../../../utils/renderField";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import * as actions from "../../../../actions";
import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import { ROOT_URL } from "../../../../utils/Global";
import axios from "axios";

const options = [
    { label: "Aberta", value: 1 },
    { label: "Fechada", value: 2 },
];

const SheetJSFT = ["xlsx", "ods"]
    .map(function(x) {
        return "." + x;
    })
    .join(",");

class Eleitores extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comarcas: this.props.comarcas || [],
            setores: this.props.setores || [],
            vinculos: this.props.vinculos || [],
            usuarios: this.props.usuarios || [],
            errados: [],
            tipoSelecionado:
                this.props.tipo_eleicao === 2
                    ? { label: "Fechada", value: 2 }
                    : { label: "Aberta", value: 1 },
            tipo_eleicao: this.props.tipo_eleicao || 1,
            popoverOpen: false,
            submitDisabled: this.props.submitDisabled,
        };

        this.fileInput = React.createRef();
        this.togglePopover = this.togglePopover.bind(this);
        this.onChangeTipoEleicao = this.onChangeTipoEleicao.bind(this);
    }

    async handleFile(file) {
        const data = new FormData();
        data.append("file", file);
        let response = await axios.post(
            `${ROOT_URL}/busca/usuariosexcel`,
            data,
        );
        console.log(response);
        this.setState({
            usuarios: [...this.state.usuarios, ...response.data.nomeCertos],
            errados: [...this.state.errados, ...response.data.nomeErrados],
        });
    }

    togglePopover() {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    onChangeTipoEleicao = tipoSelecionado => {
        this.setState({ tipoSelecionado, tipo_eleicao: tipoSelecionado.value });
    };

    handleChangeComarcas = comarcas => {
        this.setState({ comarcas });
        const stateSetores = this.state.setores;

        const idsComarcas = comarcas.map(id => parseInt(id.value, 10));
        const idsSetores = stateSetores.map(id => parseInt(id.value, 10));

        let serverData = {
            field: "nome",
            comarcas: idsComarcas,
        };

        this.props.buscaSetoresComarca(serverData, vSetores => {
            const setoresComarca = vSetores
                .map(id => parseInt(id.value, 10))
                .filter(id => idsSetores.indexOf(parseInt(id.value, 10)) !== 0);
            const setores = stateSetores.filter(
                id => setoresComarca.indexOf(parseInt(id.value, 10)) === 0,
            );

            this.setState({ setores });
        });
    };

    loadOptionsComarcas = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaComarcasNome(inputValue, comarcas => {
                callback(comarcas);
            });
        }
    };

    handleChangeSetores = setores => {
        this.setState({ setores });
    };

    loadOptionsSetores = (inputValue, callback) => {
        const comarcas = this.state.comarcas;
        const setores = this.state.setores;

        const idsComarcas = comarcas.map(comarca =>
            parseInt(comarca.value, 10),
        );
        const idsSetores = setores.map(setor => parseInt(setor.value, 10));

        if (inputValue.length > 2) {
            let serverData = {
                field: "nome",
                value: inputValue,
                comarcas: idsComarcas,
            };

            this.props.buscaSetoresComarca(serverData, setores => {
                const result = setores.filter(
                    elem => idsSetores.indexOf(parseInt(elem.value, 10)) !== 0,
                );

                callback(result);
            });
        }
    };

    handleChangeVinculos = vinculos => {
        this.setState({ vinculos });
    };

    loadOptionsVinculos = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaVinculosNome(inputValue, vinculos => {
                callback(vinculos);
            });
        }
    };

    handleChangeUsuarios = usuarios => {
        this.setState({ usuarios });
    };

    loadOptionsUsuarios = (inputValue, callback) => {
        if (inputValue.length > 2) {
            this.props.buscaUsuariosNome(inputValue, usuarios => {
                callback(usuarios);
            });
        }
    };

    renderComarcas(comarcas) {
        return (
            <div>
                <label>Comarca</label>
                <AsyncSelect
                    isMulti
                    className="basic-multi-select"
                    value={comarcas}
                    loadOptions={this.loadOptionsComarcas}
                    onChange={this.handleChangeComarcas}
                    placeholder="Buscar Comarca..."
                />
            </div>
        );
    }

    renderSetores(setores) {
        return (
            <div>
                <label>Lotação</label>
                <AsyncSelect
                    isMulti
                    className="basic-multi-select"
                    value={setores}
                    loadOptions={this.loadOptionsSetores}
                    onChange={this.handleChangeSetores}
                    placeholder="Buscar Lotação..."
                />
            </div>
        );
    }

    renderVinculos(vinculos) {
        return (
            <div>
                <label>Tipo de Vínculo</label>
                <AsyncSelect
                    isMulti
                    className="basic-multi-select"
                    value={vinculos}
                    loadOptions={this.loadOptionsVinculos}
                    onChange={this.handleChangeVinculos}
                    placeholder="Buscar Vínculo..."
                />
            </div>
        );
    }

    renderUsuarios(usuarios) {
        return (
            <>
                <label>Usuário</label>
                <div className="row">
                    <div className="col-9">
                        <AsyncSelect
                            isMulti
                            className="basic-multi-select"
                            value={usuarios}
                            loadOptions={this.loadOptionsUsuarios}
                            onChange={this.handleChangeUsuarios}
                            placeholder="Buscar Usuário..."
                        />
                    </div>

                    <div className="col-3 text-right">
                        <button
                            type="button"
                            id={"btn-importar"}
                            onClick={() => this.fileInput.current.click()}
                            className="btn btn-success"
                        >
                            <FontAwesomeIcon icon="plus-circle" /> Importar
                        </button>
                        <input
                            ref={this.fileInput}
                            type="file"
                            hidden
                            accept={SheetJSFT}
                            onChange={e => {
                                const files = e.target.files;
                                if (files && files[0])
                                    this.handleFile(files[0]);
                            }}
                        />
                        <UncontrolledTooltip
                            placement="right"
                            target={"btn-salvar"}
                        >
                            Importar Usuários
                        </UncontrolledTooltip>
                    </div>
                </div>
            </>
        );
    }

    renderSelects(comarcas, setores, vinculos, usuarios, errados) {
        return (
            <div className="row mt-3">
                <div className="col-12">
                    {this.renderComarcas(comarcas)}
                    {this.renderSetores(setores)}
                    {this.renderVinculos(vinculos)}
                    {this.renderUsuarios(usuarios)}
                    <ul style={{ color: "red" }}>
                        {" "}
                        {errados.map((element, index) => (
                            <li key={index}>{element}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    onSubmit = formProps => {
        const {
            comarcas,
            setores,
            vinculos,
            usuarios,
            tipo_eleicao,
        } = this.state;

        formProps.tipo_eleicao = tipo_eleicao;
        formProps.comarcas = [];
        formProps.setores = [];
        formProps.vinculos = [];
        formProps.usuarios = [];

        if (parseInt(tipo_eleicao, 10) === 2) {
            if (
                comarcas.length >= 1 ||
                setores.length >= 1 ||
                vinculos.length >= 1 ||
                usuarios.length >= 1
            ) {
                comarcas.forEach(comarca => {
                    formProps.comarcas.push({
                        id: parseInt(comarca.value, 10),
                        nome: comarca.label,
                    });
                });

                setores.forEach(setor => {
                    formProps.setores.push({
                        id: parseInt(setor.value, 10),
                        nome: setor.label,
                    });
                });

                vinculos.forEach(vinculo => {
                    formProps.vinculos.push({
                        id: parseInt(vinculo.value, 10),
                        nome: vinculo.label,
                    });
                });

                usuarios.forEach(usuario => {
                    formProps.usuarios.push({
                        uid: usuario.value,
                        nome: usuario.label,
                    });
                });
            } else {
                toastr.error(
                    "Atenção",
                    "Selecione ao menos um dos campos abaixo ",
                );

                return;
            }
        }

        this.props.onSubmit(formProps);
        this.props.reset();
    };

    render() {
        const {
            handleSubmit,
            pristine,
            submitting,
            submitDisabled,
            previousPage,
        } = this.props;
        const {
            popoverOpen,
            tipo_eleicao,
            comarcas,
            setores,
            vinculos,
            usuarios,
            tipoSelecionado,
            errados,
        } = this.state;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                    <div className="col-6">
                        <label>
                            Tipo de eleição
                            <span className="text-danger">*</span>
                            <i
                                id="popover-tipoeleicao"
                                onClick={this.togglePopover}
                                className="cursor-pointer"
                            >
                                <FontAwesomeIcon icon="info-circle" />
                            </i>
                            <Popover
                                placement="right"
                                isOpen={popoverOpen}
                                target="popover-tipoeleicao"
                                toggle={this.togglePopover}
                            >
                                <PopoverHeader>Tipo de eleição</PopoverHeader>
                                <PopoverBody>
                                    <b>Aberta:</b> qualquer usuário com
                                    permissão no auth poderá acessar e votar.
                                    <br />
                                    <b>Fechada:</b> Precisará liberar os acessos
                                    de quem poderá realizar a votação desta
                                    eleição.
                                </PopoverBody>
                            </Popover>
                        </label>
                        <Select
                            value={tipoSelecionado}
                            onChange={this.onChangeTipoEleicao}
                            options={options}
                        />
                    </div>
                    <div className="col-6">
                        <Field
                            name="qtda_eleitores"
                            type="number"
                            component={renderField}
                            label="Quant. Estimada de Eleitores"
                            validate={[number, minValue0, maxLength9]}
                        />
                    </div>
                </div>

                {tipo_eleicao > 1
                    ? this.renderSelects(
                          comarcas,
                          setores,
                          vinculos,
                          usuarios,
                          errados,
                      )
                    : ""}

                <div className="row mt-3">
                    <div className="col-6">
                        <button
                            type="button"
                            id={"btn-anterior-eleitores"}
                            onClick={previousPage}
                            className="btn btn-info"
                        >
                            <FontAwesomeIcon icon="arrow-left" /> Anterior
                        </button>
                        <UncontrolledTooltip
                            placement="right"
                            target={"btn-anterior-eleitores"}
                        >
                            Voltar para Candidatos
                        </UncontrolledTooltip>
                    </div>

                    <div className="col-6 text-right">
                        <button
                            type="submit"
                            id={"btn-salvar"}
                            disabled={pristine || submitting || submitDisabled}
                            className="btn btn-success"
                        >
                            <FontAwesomeIcon icon="plus-circle" /> Salvar
                            Eleição
                        </button>
                        <UncontrolledTooltip
                            placement="right"
                            target={"btn-salvar"}
                        >
                            Salvar Eleição
                        </UncontrolledTooltip>
                    </div>
                </div>
            </form>
        );
    }
}

const selector = formValueSelector("formWizardEleicao");

const mapStateToProps = state => ({
    comarcas: selector(state, "comarcas"),
    setores: selector(state, "setores"),
    vinculos: selector(state, "vinculos"),
    usuarios: selector(state, "usuarios"),
    tipo_eleicao: selector(state, "tipo_eleicao"),
});

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({
        form: "formWizardEleicao",
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    }),
)(Eleitores);
