import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Badge } from 'reactstrap';

export default class TableDocumentos extends Component {

    render() {
        if (this.props.documentos.length === 0) {
            return '';
        }

        return (
            <table className="mt-2 table table-sm table-striped">
                <thead>
                    <tr>
                        <th>Documento</th>
                        <th>Link</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.documentos.map((documento, index) => {
                            return(
                                <tr key={index}>
                                    <td>
                                        {documento.url}
                                    </td>
                                    <td>
                                        <Badge href={`https://cdn-0.mpma.mp.br/evoto/${documento.url}`} target="_blank" color="light">Baixar Documento</Badge>  
                                    </td>
                                    <td>
                                        <Button type="button" className="btn-danger btn-sm" onClick={() => this.props.removeDocumento(index, documento)}>
                                            <FontAwesomeIcon icon="minus-circle" />
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        );
    }
}