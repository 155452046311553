import React, { Component } from 'react'
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from '../../../../actions';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap';
import CandidatosForm from "./CandidatosForm";
import CandidatosTable from "./CandidatosTable";

class CandidatosEdit extends Component {
    constructor(props) {
        super(props);
        
        if (this.props.candidatos !== undefined) {
            this.state = {
                candidatos: this.props.candidatos
            }
        } else {
            this.state = {
                candidatos: []
            };
        }
        
        this.removeCandidato = this.removeCandidato.bind(this);
        this.handleOnAddCandidato = this.handleOnAddCandidato.bind(this);
    }

    removeCandidato(index, foto) {
        let candidatos = this.state.candidatos;

        const data = new FormData();

        data.append('arquivo_nome', foto)

        if( foto !== 'voto_branco.png')
            this.props.deleteFileCdn(data)

        candidatos.splice(index, 1);
        this.setState({ candidatos });
    }

    handleOnAddCandidato(data) {
        let candidatos = this.state.candidatos;

        let valida = candidatos.filter(function(item){
            return item.nome.toLowerCase().search(data.nome.toLowerCase()) !== -1
        });

        if(valida.length === 0) {
            candidatos.push(data);
        }
        else {
            toastr.error('Atenção', 'O candidato ' + data.nome + ' já está participando desta eleição!');

            return;
        }

        this.setState({ candidatos });
    }

    onSubmit = formProps => {
        if (this.state.candidatos.length === 0) {
            toastr.error('Atenção', 'Você deve adicionar pelo menos um candidato');

            return;
        }        
        
        if(this.props.candidatoForm.nome !== "" || this.props.candidatoForm.foto !== null) {
            toastr.error('Atenção', 'Existe candidato não adicionado, verifique o formulário.');

            return;
        }

        formProps.candidatos = this.state.candidatos;

        this.props.onSubmit();
    };

    render() {
        const {handleSubmit, previousPage} = this.props;
        const { candidatos } = this.state;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <CandidatosForm onAddCandidato={this.handleOnAddCandidato}/>

                <CandidatosTable candidatos={candidatos} removeCandidato={this.removeCandidato} />

                <div className="row mt-3">
                    <div className="col-6">
                        <button type="button" id={'btn-anterior-candidatos'} onClick={previousPage} className="btn btn-info">
                            <FontAwesomeIcon icon="arrow-left"/> Anterior
                        </button>
                        <UncontrolledTooltip placement="right" target={'btn-anterior-candidatos'}>
                            Voltar para Documentos
                        </UncontrolledTooltip>
                    </div>

                    <div className="col-6 text-right">
                        <button type="submit" id={'btn-proximo-candidatos'} className="btn btn-info">
                            <FontAwesomeIcon icon="arrow-right"/> Salvar/Próximo
                        </button>
                        <UncontrolledTooltip placement="right" target={'btn-proximo-candidatos'}>
                            Avançar para Eleitores
                        </UncontrolledTooltip>
                    </div>
                </div>
            </form>
        );
    }
};

const selector = formValueSelector('formWizardEleicaoEdit')

const mapStateToProps = state => ({
    candidatos: selector(state, 'candidatos'), 
    candidatoForm : state.formulario.candidato 
});

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ 
        form: 'formWizardEleicaoEdit',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true
    })
)(CandidatosEdit);