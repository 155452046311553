import React, { Component } from 'react';
import { connect } from 'react-redux';

import EleicaoBusca from './EleicaoBusca';
import EleicaoTable from './EleicaoTable';

class EleicaoGridPage extends Component {
    render() {
        //Verificação adicional para evitar carregamento indevido 
        if(this.props.loggedUser.perfil !== 1) return "";

        return(
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Manter Eleições</h1>
                </div>

                <EleicaoBusca />

                <EleicaoTable />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { loggedUser: state.auth.loggedUser };
}

export default connect(mapStateToProps)(EleicaoGridPage);