import React from 'react';
import moment from 'moment';

export default class PrintTemplate extends React.Component {
    render() {
        return (
            <div className="print-template">
                <div className="footer">
                    <br></br>
                    <div className="row">
                        <div className="col-sm text-center">
                            <div className="small-imprimir">"{moment().format('YYYY')} - O Ministério Público na indução de políticas públicas"</div>
                            <div className="small-imprimir">Av. Prof. Carlos Cunha, s/no Calhau – CEP: 65076-820 – São Luís/MA (98) 3219-1600</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm text-right">
                            <span className="small-imprimir">Página {this.props.pageNum} de {this.props.totalPages}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
