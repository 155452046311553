import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Moment from "react-moment";
import ReactTable from "react-table";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalPrint from "./ModalPrint";
import ModalVoters from "../eleicao/modal/ModalVoters";

class ZeresimaTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            descEleicao: "",
            data_inicial: null,
            data_final: null,
            showModalVoter: false,
        };
    }

    voterEleicao(idEleicao) {
        if(!this.props.simuladaZeresima) {
            this.props.getEleicao(idEleicao, () => {
                this.setState({
                    showModalVoter: true,
                });
            });
        } else {
            this.props.getEleicaoSimulada(idEleicao, () => {
                this.setState({
                    showModalVoter: true,
                });
            });
        }
    }

    toggleShowModalVoter = () => {
        this.setState({ showModalVoter: !this.state.showModalVoter});
    }

    printEleicaoZeresima(idEleicao, descEleicao, qtdaSelecaoCandidato, data_inicial, data_final) {
        this.props.getZeresima(idEleicao, this.props.simuladaZeresima, (result) => {
            this.setState({ 
                idEleicao, 
                descEleicao, 
                qtdaSelecaoCandidato, 
                data_inicial, 
                data_final
            });
            this.props.tratarCandidatos(result.data.votacao, qtdaSelecaoCandidato);
            this.props.toggleModalPrint(!this.props.showModalPrint);
        });
    }

    renderTable(dados) {
        return (
            <ReactTable
                data={dados}
                columns={[
                    {
                        Header:
                            "Eleições " +
                            (this.props.simuladaZeresima ? "Simuladas" : ""),
                        columns: [
                            {
                                Header: "#",
                                accessor: "id",
                                maxWidth: 40,
                                Cell: row => <>{row.index + 1}</>,
                            },
                            {
                                Header: "Data inicial",
                                accessor: "data_inicial",
                                resizable: false,
                                minWidth: 148,
                                maxWidth: 148,
                                Cell: row => (
                                    <Moment format="DD/MM/YYYY HH:mm">
                                        {row.value}
                                    </Moment>
                                ),
                            },
                            {
                                Header: "Data final",
                                accessor: "data_final",
                                resizable: false,
                                minWidth: 148,
                                maxWidth: 148,
                                Cell: row => (
                                    <Moment format="DD/MM/YYYY HH:mm">
                                        {row.value}
                                    </Moment>
                                ),
                            },
                            {
                                Header: "Descrição",
                                accessor: "descricao",
                            },
                            {
                                Header: "Liberação",
                                accessor: "liberacao_descricao",
                            },
                            {
                                Header: "Branco",
                                accessor: "branco",
                                className: "text-center",
                                maxWidth: 80,
                                Cell: row => (row.value === 1 ? "Sim" : "Não"),
                            },
                            {
                                Header: "Seleção",
                                accessor: "selecao_candidato",
                                className: "text-left",
                                maxWidth: 80,
                                Cell: row =>
                                    row.value === 1 ? "Único" : "Múltiplo",
                            },
                            {
                                Header: "Status",
                                accessor: "status",
                                className: "text-center",
                                maxWidth: 80,
                                Cell: row =>
                                    row.value === "fechada" ||
                                    row.value === "expirada" ? (
                                        <span className="badge badge-pill badge-danger">
                                            {row.value}
                                        </span>
                                    ) : row.value === "iniciada" ? (
                                        <span className="badge badge-pill badge-success">
                                            {row.value}
                                        </span>
                                    ) : (
                                        <span className="badge badge-pill badge-info">
                                            {row.value}
                                        </span>
                                    ),
                            },
                            {
                                Header: "Porcentagem",
                                className: "text-center",
                                accessor: "porcentagem",
                                Cell: row => (
                                    <>
                                        <React.Fragment>
                                            <small
                                                onClick={() =>
                                                    this.voterEleicao(
                                                        row.row.id,
                                                    )
                                                }
                                                style={{
                                                    float: "left",
                                                    position: "relative",
                                                    left: "40%",
                                                }}
                                                className="mt-1 cursor-pointer"
                                            >
                                                {row.value.total_votados}/
                                                {row.value.total_eleitores ||
                                                    "∞"}
                                            </small>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "#dadada",
                                                    borderRadius: "2px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: `${row.value.porcentagem}%`,
                                                        height: "100%",
                                                        backgroundColor:
                                                            row.value
                                                                .porcentagem >
                                                            66
                                                                ? "#85cc00"
                                                                : row.value
                                                                      .porcentagem >
                                                                  33
                                                                ? "#ffbf00"
                                                                : "#ff2e00",
                                                        borderRadius: "2px",
                                                        transition:
                                                            "all .2s ease-out",
                                                    }}
                                                ></div>
                                            </div>
                                        </React.Fragment>
                                    </>
                                ),
                            },
                            {
                                Header: "",
                                accessor: "id",
                                className: "text-center",
                                maxWidth: 80,
                                Cell: row => (
                                    <div>
                                        <button
                                            id={"btn-print-" + row.value}
                                            onClick={() =>
                                                this.printEleicaoZeresima(
                                                    row.value,
                                                    row.original.descricao,
                                                    row.original.qtda_selecao_candidato,
                                                    row.original.data_inicial,
                                                    row.original.data_final,
                                                )
                                            }
                                            className="btn btn-dark btn-sm"
                                        >
                                            <FontAwesomeIcon icon="print" />
                                        </button>
                                        <UncontrolledTooltip
                                            placement="right"
                                            target={"btn-print-" + row.value}
                                        >
                                            Imprimir
                                        </UncontrolledTooltip>
                                    </div>
                                ),
                            },
                        ],
                    },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultSorted={[
                    {
                        id: "index",
                        desc: false,
                    },
                ]}
            />
        );
    }

    render() {
        const {
            idEleicao,
            descEleicao,
            qtdaSelecaoCandidato,
            data_inicial, 
            data_final
        } = this.state;

        const {
            showModalPrint,
            simuladaZeresima,
            eleicoesZeresimaOriginais,
            eleicoesZeresimaSimuladas,
            isLoading,
        } = this.props;

        if (!isLoading) {
            return "";
        }

        return (
            <div>
                {simuladaZeresima
                    ? this.renderTable(eleicoesZeresimaSimuladas)
                    : this.renderTable(eleicoesZeresimaOriginais)}

                <ModalPrint
                    showModalPrint={showModalPrint}
                    modalTipo="zeresima"
                    idEleicao={idEleicao}
                    descEleicao={descEleicao}
                    qtdaSelecaoCandidato={qtdaSelecaoCandidato}
                    dataInicial={data_inicial}
                    dataFinal={data_final}
                />

                <ModalVoters 
                    showModalVoter={this.state.showModalVoter} 
                    toggleShowModalVoter={this.toggleShowModalVoter}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.eleicao.filter,
        eleicoesZeresimaOriginais: state.eleicao.originais,
        eleicoesZeresimaSimuladas: state.eleicao.simuladas,
        simuladaZeresima: state.relatorio.simulada,
        showModalPrint: state.modal.showModalPrint,
    };
}

export default connect(mapStateToProps, actions)(ZeresimaTable);
