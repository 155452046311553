import React, { Component } from "react";
import { Modal } from "reactstrap";
import { filterTableMethod } from "../../../utils/renderField";
import { connect } from "react-redux";
import * as actions from "../../../actions";

import ReactTable from "react-table";

class ModalVoters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalVoter: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({ showModalVoter: props.showModalVoter });
    }

    toggle() {
        this.setState({ showModalVoter: !this.state.showModalVoter });
        !!this.props.toggleShowModalVoter 
            && this.props.toggleShowModalVoter();
    }

    renderEleitores(eleitores) {
        if (eleitores.length === 0) {
            return "Esta eleição não possui eleitores.";
        }

        return (
            <ReactTable
                data={eleitores}
                columns={[
                    {
                        Header: "Eleitores",
                        columns: [
                            {
                                Header: "#",
                                accessor: "id",
                                filterable: false,
                                maxWidth: 40,
                                Cell: row => <>{row.index + 1}</>,
                            },
                            {
                                Header: "Nome",
                                accessor: "nome",
                            },
                            {
                                Header: "Comarca",
                                accessor: "comarca",
                            },
                            {
                                Header: "Lotação",
                                accessor: "lotacao",
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "index",
                        desc: false,
                    },
                ]}
            />
        );
    }

    render() {
        
        const {
            simulada,
            eleicaoSimulada,
            eleicao,
        } = this.props;
        
        if ((!eleicao && !simulada) || (!eleicaoSimulada && simulada)) {
            return "";
        }

        return (
            <>
                <Modal
                    className="modal-evoto"
                    isOpen={this.state.showModalVoter}
                    toggle={this.toggle}
                    size="xl"
                >
                    { !simulada
                        ? this.renderEleitores(eleicao.eleitores) 
                        : this.renderEleitores(eleicaoSimulada.eleitores) }
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        eleicao: state.eleicao.eleicao,
        eleicaoSimulada: state.eleicao.eleicaoSimulada,
        simulada: state.relatorio.simulada,
    };
}

export default connect(mapStateToProps, actions)(ModalVoters);
