import React from 'react';
import ReactDOM from 'react-dom';
import reducers from './reducers';
import jwt_decode from "jwt-decode";
import reduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import requireAuth from "./utils/requireAuth";
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import setAuthorizationToken from './utils/setAuthorizationToken';
import LogoutTime from './utils/LogoutTime';
import App from './components/App';
import Login from './components/auth/Login';
import Zeresima from './components/relatorio/Zeresima';
import Votacao from './components/relatorio/Votacao';
import EleicaoGridPage from "./components/eleicao/EleicaoGridPage";
import EleicoesLiberadas from "./components/eleicao/EleicoesLiberadas";
import LiberacaoGridPage from "./components/liberacao/LiberacaoGridPage";
import EleicaoLiberacaoPage from "./components/eleicao/EleicaoLiberacaoPage";

import './assets/css/styles.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-table/react-table.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faPrint, faEye, faCalendarAlt, faDownload, faUserCircle, faInfoCircle, faMinusCircle, faPlusCircle, faTrashAlt, faArrowLeft, faArrowRight }  
from '@fortawesome/free-solid-svg-icons';

import 'react-datetime/css/react-datetime.css';

library.add(faEdit, faPrint, faEye, faCalendarAlt, faDownload, faUserCircle, faInfoCircle, faMinusCircle, faPlusCircle, faTrashAlt, faArrowLeft, faArrowRight );

let token = localStorage.getItem('token');
let loggedUser = {};

if (token) {

    const now = Date.now().valueOf() / 1000

    let user = jwt_decode(token);

    if (typeof user.exp !== 'undefined' && user.exp < now) {
        localStorage.removeItem('token');
        localStorage.removeItem('timeactive');
    }

    loggedUser = {
        token: token,
        nome: user.nome,
        uid: user.uid,
        email: user.email,
        perfil: parseInt(user.perfil, 10)
    };
}

const store = createStore(reducers, { auth: { loggedUser } }, composeWithDevTools(
    applyMiddleware(reduxThunk)
));

setAuthorizationToken(token);

ReactDOM.render(
    <Provider store={store}>
        <div>
            <BrowserRouter>
                <App>
                    <LogoutTime />
                    <Route path="/login" component={Login} />
                    <Route path="/" exact component={requireAuth(EleicoesLiberadas)} />
                    <Route path="/liberacao" component={requireAuth(LiberacaoGridPage)} />
                    <Route path="/eleicao" component={requireAuth(EleicaoGridPage)} />
                    <Route path="/eleicaoliberacao" component={requireAuth(EleicaoLiberacaoPage)} />
                    <Route path="/relatorio-zeresima" component={requireAuth(Zeresima)} />
                    <Route path="/relatorio-votacao" component={requireAuth(Votacao)} />
                </App>
            </BrowserRouter>
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
        </div>
    </Provider>,
    document.querySelector('#root')
);