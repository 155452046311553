import { FETCH_LIBERACOES, CREATE_LIBERACAO, EDIT_LIBERACAO, GET_LIBERACAO, FETCH_ELEICOES_USUARIO, GET_LIBERACOES_USUARIO, SET_LIBERACAO_ID } from '../actions/types';

const INITIAL_STATE = { all: [], liberacao: null, eleicoes: [], liberacoes: [], liberacaoSelect: [] };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_LIBERACOES:
            return { ...state, all: action.payload };
        case CREATE_LIBERACAO:
            return { ...state };
        case EDIT_LIBERACAO:
            return { ...state };
        case GET_LIBERACAO:
            return { ...state, liberacao: action.payload };
        case GET_LIBERACOES_USUARIO:
            return { ...state, liberacoes: action.payload.data };
        case FETCH_ELEICOES_USUARIO:
            return { ...state, eleicoes: action.payload };
        case SET_LIBERACAO_ID:
            return { ...state, liberacaoSelect: action.payload }; 
        default:
            return state;
    }
}
