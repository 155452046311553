import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Moment from "react-moment";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

import ModalView from "./modal/ModalView";
import ModalEdit from "./modal/ModalEdit";

class LiberacaoTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showModalEdit: false,
        };
    }

    viewLiberacao(idLiberacao) {
        this.props.getLiberacao(idLiberacao, () => {
            this.setState({ showModal: true, showModalEdit: false });
        });
    }

    editLiberacao(idLiberacao) {
        this.props.getLiberacao(idLiberacao, () => {
            this.setState({ showModalEdit: true, showModal: false });
        });
    }

    componentDidMount() {
        this.props.fetchLiberaoes();
    }

    render() {
        return (
            <div>
                <ReactTable
                    data={this.props.liberacoes}
                    columns={[
                        {
                            Header: "Liberações",
                            columns: [
                                {
                                    Header: "#",
                                    accessor: "id",
                                    maxWidth: 40,
                                    Cell: row => <>{row.index + 1}</>,
                                },
                                {
                                    Header: "Data inicial",
                                    accessor: "data_inicial",
                                    resizable: false,
                                    minWidth: 148,
                                    maxWidth: 148,
                                    Cell: row => (
                                        <Moment format="DD/MM/YYYY HH:mm">
                                            {row.value}
                                        </Moment>
                                    ),
                                },
                                {
                                    Header: "Data final",
                                    accessor: "data_final",
                                    resizable: false,
                                    minWidth: 148,
                                    maxWidth: 148,
                                    Cell: row => (
                                        <Moment format="DD/MM/YYYY HH:mm">
                                            {row.value}
                                        </Moment>
                                    ),
                                },
                                {
                                    Header: "Descrição",
                                    accessor: "descricao",
                                    Cell: row => row.value,
                                },
                                {
                                    Header: "Status",
                                    accessor: "status",
                                    className: "text-center",
                                    maxWidth: 100,
                                    Cell: row =>
                                        row.value === "ativa" ? (
                                            <span className="badge badge-pill badge-success">
                                                {row.value}
                                            </span>
                                        ) : row.value === "expirada" ? (
                                            <span className="badge badge-pill badge-danger">
                                                {row.value}
                                            </span>
                                        ) : (
                                            <span className="badge badge-pill badge-warning">
                                                {row.value}
                                            </span>
                                        ),
                                },
                                {
                                    Header: "",
                                    accessor: "id",
                                    className: "text-left",
                                    resizable: false,
                                    minWidth: 120,
                                    maxWidth: 120,
                                    Cell: row => (
                                        <div>
                                            <button
                                                id={"btn-eye-" + row.value}
                                                onClick={() =>
                                                    this.viewLiberacao(
                                                        row.value,
                                                    )
                                                }
                                                className="btn btn-primary btn-sm ml-1"
                                            >
                                                <FontAwesomeIcon icon="eye" />
                                            </button>
                                            <UncontrolledTooltip
                                                placement="right"
                                                target={"btn-eye-" + row.value}
                                            >
                                                Visualizar Liberação
                                            </UncontrolledTooltip>

                                            <button
                                                id={"btn-edit-" + row.value}
                                                onClick={() =>
                                                    this.editLiberacao(
                                                        row.value,
                                                    )
                                                }
                                                className="btn btn-warning btn-sm ml-1"
                                            >
                                                <FontAwesomeIcon icon="edit" />
                                            </button>
                                            <UncontrolledTooltip
                                                placement="right"
                                                target={"btn-edit-" + row.value}
                                            >
                                                Editar Liberação
                                            </UncontrolledTooltip>
                                        </div>
                                    ),
                                },
                            ],
                        },
                    ]}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando..."
                    noDataText="Nenhum registro foi encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                    className="-striped -highlight"
                    pageSizeOptions={[15, 30, 50, 100]}
                    defaultPageSize={15}
                    defaultSorted={[
                        {
                            id: "index",
                            desc: false,
                        },
                    ]}
                />

                <ModalView showModal={this.state.showModal} />

                <ModalEdit showModalEdit={this.state.showModalEdit} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { liberacoes: state.liberacao.all };
}

export default connect(mapStateToProps, actions)(LiberacaoTable);
