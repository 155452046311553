import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Card, CardTitle, CardSubtitle, Button, Badge } from 'reactstrap'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import ModalWizardVotar from './modal/ModalWizardVotar';


class EleicoesLiberadas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tipoEleicoes: [
                {
                    descricao: "Original", 
                    ativa: true, 
                    render: () => this.renderOriginais(this.props.eleicoesOriginais),
                    backgroundColor: '#fff',
                },
                {
                    descricao: "Simulada", 
                    ativa: false, 
                    render: () => this.renderSimuladas(this.props.eleicoesSimuladas),
                    backgroundColor: '#fffecc',
                }
            ],
        };

        this._isMounted = false
        this.handleOpen = this.handleOpen.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.fetchEleicoes()
    }

    componentWillUnmount() {
        this.changeBackgroundColor("#fff");
        this._isMounted = false
    }

    fetchEleicoes = async () => {
        this.setState({ isLoading: true });
        return await this.props.fetchEleicoesLiberadas(() => {
            if(this._isMounted){
                this.setState({ isLoading: false })
            }
        });
    }

    handleOpen = (e) => {
        this.setState({ 
            eleicaoSimulada: e.target.dataset.simulada,
            eleicaoId: e.target.dataset.id,
            eleicaoIndex: e.target.dataset.index
        });

        this.props.toggleModalVotarEleicao(!this.props.showModalVotarEleicao);
    }

    renderOriginais(eleicoesOriginais) {
        if(eleicoesOriginais.length === 0){
            return (
                <div className="container" key="sem-eleicao-original">
                    <div className="alert alert-primary mt-2" role="alert" style={{fontSize: "1.2rem"}}>
                        <FontAwesomeIcon icon="info-circle"/> Não existem eleições originais nas quais você pode votar.
                    </div>
                </div>
            )
        }

        return eleicoesOriginais.map((eleicao, index) => (
                <div key={index} className="col-sm-12 col-md-4 mb-3">

                    <Card body style={eleicao.votada !== 0 ? {backgroundColor: '#fbfbfb', borderColor: '#ddd'} : {}} >
                        <CardTitle>{eleicao.descricao}</CardTitle>
                        <CardSubtitle className="mb-3">{eleicao.documento ? <Badge href={`https://cdn-0.mpma.mp.br/evoto/${eleicao.documento}`} target="_blank" color="light"><FontAwesomeIcon icon="download" style={{fontSize: '14px'}} /> Documento</Badge> : ''}</CardSubtitle>

                        <div className="m-auto">
                            <div className="float-left">
                                <FontAwesomeIcon icon="calendar-alt" style={{fontSize: '60px'}} />
                            </div>
                            <div className="float-left ml-2">
                                <p className="mb-0 mt-2 border-bottom">De: <Moment format="DD/MM/YYYY">{eleicao.data_inicial}</Moment> <span className="text-mini"><Moment format="HH:mm">{eleicao.data_inicial}</Moment></span></p>
                                <p>Até: <Moment format="DD/MM/YYYY">{eleicao.data_final}</Moment> <span className="text-mini"><Moment format="HH:mm">{eleicao.data_final}</Moment></span></p>
                            </div>
                        </div>

                        <p><span className="mt-3 mb-3 badge-pill badge badge-primary">Escolha {eleicao.selecao_candidato === 1 ? 'Única' : 'Múltipla'}</span></p>

                        {eleicao.votada === 0 ? <Button data-id={eleicao.id} data-simulada={false} data-index={index} onClick={this.handleOpen} color="primary">Votar</Button> : <Button color="success" disabled>Voto já Computado</Button>}  
                            
                    </Card>
                </div> 
            )
        );

    }

    renderSimuladas(eleicoesSimuladas) {
        let cardEleicoesSimuladas = eleicoesSimuladas.map((eleicao, index) => (
                <div key={index} className="col-sm-12 col-md-4 mb-3">
                    <Card body style={eleicao.votada !== 0 ? {backgroundColor: '#fbfbfb', borderColor: '#ddd'} : {}} >
                        <CardTitle>{eleicao.descricao}</CardTitle>
                        <CardSubtitle className="mb-3">{eleicao.documento ? <Badge href={`https://cdn-0.mpma.mp.br/evoto/${eleicao.documento}`} target="_blank" color="light"><FontAwesomeIcon icon="download" style={{fontSize: '14px'}} /> Documento</Badge> : ''}</CardSubtitle>

                        <div className="m-auto">
                            <div className="float-left">
                                <FontAwesomeIcon icon="calendar-alt" style={{fontSize: '60px'}} />
                            </div>
                            <div className="float-left ml-2">
                                <p className="mb-0 mt-2 border-bottom">De: <Moment format="DD/MM/YYYY">{eleicao.data_inicial}</Moment> <span className="text-mini"><Moment format="HH:mm">{eleicao.data_inicial}</Moment></span></p>
                                <p>Até: <Moment format="DD/MM/YYYY">{eleicao.data_final}</Moment> <span className="text-mini"><Moment format="HH:mm">{eleicao.data_final}</Moment></span></p>
                            </div>
                        </div>

                        <p><span className="mt-3 mb-3 badge-pill badge badge-warning">Escolha {eleicao.selecao_candidato === 1 ? 'Única' : 'Múltipla'}</span></p>

                        {eleicao.votada === 0 ? <Button data-id={eleicao.id} data-simulada={true} data-index={index} onClick={this.handleOpen} color="warning">Votar Simulado</Button> : <Button color="success" disabled>Voto já Computado</Button>}     
                        
                    </Card>
                </div> 
            )
        );

        return (
            <div className="row" key="eleicoes-simulada">
                <div className="titulo-simulada">
                    <h1>As eleições apresentadas são do tipo Simulada</h1>
                </div>
                {cardEleicoesSimuladas}
            </div>
        )
    }

    changeBackgroundColor(color) {
        document.documentElement.style.setProperty(
            '--backgroundColor',
            color
        );
    }

    toggleTipoEleicao = () => {
        let tipoEleicoes = [...this.state.tipoEleicoes
            .map(eleicao => {
                eleicao.ativa = !eleicao.ativa;
                return eleicao;
            })];
        this.setState({tipoEleicoes});
        this.changeBackgroundColor(tipoEleicoes.find(eleicao => eleicao.ativa).backgroundColor);
    }

    renderSwitchTipoEleicao(){
        let { descricao } = this.state.tipoEleicoes.find(eleicao => eleicao.ativa);
        return (
            <Button 
                color="primary"
                onClick={() => this.toggleTipoEleicao()}
            >
                { 
                    descricao === "Original"
                    ? "Exibir Eleições Simuladas"
                    : "Ocultar Eleições Simuladas"
                }
            </Button>
        );
    }

    renderEleicoes() {
        return this.state.tipoEleicoes
            .filter(eleicao => eleicao.ativa)
            .map(({render}) => render());
    }
    
    render() {
                
        let content;

        
        const { isLoading, eleicaoSimulada, eleicaoIndex, eleicaoId } = this.state;
        const { eleicoesOriginais, eleicoesSimuladas } = this.props;

        if (isLoading) {
            return ''
        }

        if (eleicoesOriginais.length === 0 && eleicoesSimuladas.length === 0) {
            content =   <div className="container">
                            <div className="alert alert-warning mt-2" role="alert">
                                <FontAwesomeIcon icon="info-circle"/> Não existem eleições nas quais você pode votar.
                            </div>
                        </div>
        } else {
            content = <div className="container">
                        <div className="row text-center">
                            { this.renderEleicoes() }
                        </div>
                        <ModalWizardVotar 
                            eleicoesOriginais={eleicoesOriginais} 
                            eleicoesSimuladas={eleicoesSimuladas} 
                            simulada={eleicaoSimulada} 
                            eleicaoIndex={eleicaoIndex} 
                            eleicaoId={eleicaoId}
                        />
                    </div>
        }

        return (
            <div className="row break-container">
                <div className="switch-tipo-eleicao col-lg-2 col-md-12 col-sm-12">
                    { this.renderSwitchTipoEleicao() }
                </div>
                <div className="col-lg-10 col-md-12 col-sm-12">
                    { content }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { 
        eleicoesOriginais: state.eleicao.originais, 
        eleicoesSimuladas: state.eleicao.simuladas, 
        showModalVotarEleicao: state.modal.showModalVotarEleicao
    };
}

export default connect(mapStateToProps, actions)(EleicoesLiberadas);