import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import * as actions from "../../../actions";

import AsyncSelect from 'react-select/lib/Async';
import ModalTable from "./ModalTable";
import UploadDocumento from "../../../utils/UploadDocumento";
import TableDocumentos from "../../../utils/TableDocumentos";
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { validDateTime, anoInicial, maioPalavra46, renderField, renderDateTimeField, required, minLength3, maxLength255 } from '../../../utils/renderField';

class ModalCreateForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            usuarios: [],
            documentos: [],
            single: '',
            submitDisabled: false
        };

        this.removeDocumento = this.removeDocumento.bind(this);
        this.removeUsuario = this.removeUsuario.bind(this);
        this.handleOnAddArquivo = this.handleOnAddArquivo.bind(this);
    }

    removeDocumento(index, documento) {
        let documentos = this.state.documentos;

        const data = new FormData()
        data.append('arquivo_nome', documento.url)

        this.props.deleteFileCdn(data)
        .then(() => {
            documentos.splice(index, 1)
            this.setState({ documentos })
        })
    }

    removeUsuario(index, usuario) {
        let usuarios = this.state.usuarios;

        usuarios.splice(index, 1);

        this.props.change(usuario.uid + '___permissao', '');

        this.setState({ usuarios });
    }

    handleChange = name => value => {
        if (value.value === undefined || value.label === undefined) {
            return;
        }

        const usuarios = this.state.usuarios;

        let usuario = {
            uid: value.value,
            nome: value.label,
            eleicoes_criadas: 0
        };

        usuarios.push(usuario);

        this.setState({ usuarios });
    };

    loadOptions = (inputValue, callback) => {
        if (inputValue.length > 2) {

            const stateUsuarios = this.state.usuarios;

            this.props.buscaUsuariosNome(inputValue, (usuarios) => {

                const listaUsuarios = usuarios.filter(u => stateUsuarios.map(u=>u.uid).indexOf(u.value) === -1)
                
                callback(listaUsuarios);
            });
        }
    };

    handleOnAddArquivo(data) {
        let documentos = this.state.documentos;

        let valida = documentos.filter(function(item){
            return item.url.toLowerCase().search(data.url.toLowerCase()) !== -1
        });
        if(valida.length === 0) {
            documentos.push(data);
        }
        else {
            toastr.error('Atenção', 'O documento ' + data.url + ' já está adicionado nesta liberação!');

            return;
        }

        this.setState({ documentos });
    }

    onSubmit = formProps => {
        this.setState({ submitDisabled: true });

        let documentos = this.state.documentos;

        let hasErrors = false;

        if (formProps.data_inicial >= formProps.data_final) {
            toastr.error('Atenção', 'A data final deve ser maior que a data inicial');

            hasErrors = true;
        }

        const stateUsuarios = this.state.usuarios.map(u=>u.uid);

        let usuarios = [];
        for (let key in formProps) {
            if (formProps.hasOwnProperty(key) && key.search("___") > 0) {
                let usuario = key.split("___");

                const usuarioUid = stateUsuarios.filter(u => u.replace(".", "") === usuario[0])                

                usuarios.push({'uid': usuarioUid[0], 'permissao': formProps[key]});
            }
        }

        if (usuarios.length === 0) {
            toastr.error('Atenção', 'Você deve adicionar permissões para pelo menos um usuário');

            hasErrors = true;
        }

        if (!hasErrors) {
            let serverData = {
                'data_inicial': moment(formProps.data_inicial).format('YYYY-MM-DDTHH:mm'),
                'data_final': moment(formProps.data_final).format('YYYY-MM-DDTHH:mm'),
                'descricao': formProps.descricao,
                'usuarios': usuarios,
                'documentos': documentos,
                'ip': this.props.ipUsuario
            };

            this.props.createLiberacao(serverData, (msg) => {
                this.setState({ submitDisabled: false });
                if(msg.erro !== undefined) {
                    toastr.error('Erro', msg.erro);
                }
                else {
                    toastr.success('Sucesso', msg);
                    this.props.fetchLiberaoes();
                    this.props.closeModal();
                }
            });
        } else {
            this.setState({ submitDisabled: false });
        }
    };

    render() {
        const { handleSubmit, valid, pristine, submitting } = this.props;
        const { usuarios, documentos, submitDisabled } = this.state;

        return (
            <div>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <div className="row">
                        <div className="col-4">
                            <Field
                                name="data_inicial"
                                label="Data inicial"
                                component={renderDateTimeField}
                                required
                                validate={[required, validDateTime, anoInicial]}
                            />
                        </div>
                        <div className="col-4">
                            <Field
                                name="data_final"
                                label="Data final"
                                component={renderDateTimeField}
                                required
                                validate={[required, validDateTime, anoInicial]}
                            />
                        </div>
                        <div className="col-4">
                            <Field
                                name="descricao"
                                type="text"
                                component={renderField}
                                label="Descrição"
                                required
                                validate={[required, maioPalavra46, minLength3, maxLength255]}
                            />
                        </div>
                    </div>

                    <h5 className="mt-2">Usuários<span className="text-danger">*</span></h5>

                    <AsyncSelect
                        value=''
                        loadOptions={this.loadOptions}
                        onChange={this.handleChange('single')}
                        placeholder="Buscar Usuário..."
                    />

                    <UploadDocumento onAddArquivo={this.handleOnAddArquivo} uploadMultiplo={true} uploadTitle='da liberação'/>

                    <ModalTable usuarios={usuarios} removeUsuario={this.removeUsuario} />

                    <TableDocumentos documentos={documentos} removeDocumento={this.removeDocumento} />

                    <div className="mt-3 text-right">
                        <button
                            disabled={pristine || submitting || !valid || submitDisabled}
                            type="submit"
                            className="btn btn-primary"
                            onClick={this.handleClose}>Salvar liberação</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { ipUsuario: state.auth.ipUsuario };
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ form: 'formLiberacao' })
)(ModalCreateForm);