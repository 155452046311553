import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import WizardForm from './form/WizardForm';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

class ModalCreate extends React.Component {
    constructor(props) {
        super(props);

        this.toggleEleicao = this.toggleEleicao.bind(this);
    }

    toggleEleicao() {
        this.props.toggleModalEleicao(!this.props.showModalEleicao);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.showModalEleicao} toggle={this.toggleEleicao} size="lg">
                    <ModalHeader toggle={this.toggleEleicao}>Criar nova eleição</ModalHeader>
                    <ModalBody>
                        <WizardForm closeModal={this.toggleEleicao} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { showModalEleicao: state.modal.showModalEleicao };
}

export default connect(mapStateToProps, actions)(ModalCreate);