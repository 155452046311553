import { GET_FORM_CANDIDATOS } from '../actions/types';

const INITIAL_STATE = { candidato: [], documentos: [] };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_FORM_CANDIDATOS:
            return { ...state, candidato: action.payload };
        default:
            return state;
    }
}
