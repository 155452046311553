import React from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    Badge,
    Row,
    Col,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Moment from "react-moment";
import { filterTableMethod } from "../../../utils/renderField";

class ModalView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            activeTab: "1",
        };

        this.toggle = this.toggle.bind(this);
        this.toggleTabs = this.toggleTabs.bind(this);
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    toggleTabs(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            showModal: props.showModal,
        });
    }

    renderUsuariosTable(usuarios) {
        if (usuarios.length === 0) {
            return "Esta liberação não possui usuários com permissões.";
        }

        return (
            <ReactTable
                data={usuarios}
                columns={[
                    {
                        Header: "Usuários",
                        columns: [
                            {
                                Header: "#",
                                accessor: "id",
                                maxWidth: 40,
                                Cell: row => <>{row.index + 1}</>,
                            },
                            {
                                Header: "Nome",
                                accessor: "nome",
                            },
                            {
                                Header: "Incluir",
                                accessor: "permitir_incluir_txt",
                                maxWidth: 90,
                                Cell: row =>
                                    row.value === "Sim" ? (
                                        <Badge color="success">Sim</Badge>
                                    ) : (
                                        <Badge color="danger">Não</Badge>
                                    ),
                            },
                            {
                                Header: "Finalizar",
                                accessor: "permitir_finalizar_txt",
                                maxWidth: 90,
                                Cell: row =>
                                    row.value === "Sim" ? (
                                        <Badge color="success">Sim</Badge>
                                    ) : (
                                        <Badge color="danger">Não</Badge>
                                    ),
                            },
                            {
                                Header: "Visualizar",
                                accessor: "permitir_visualizar_txt",
                                maxWidth: 90,
                                Cell: row =>
                                    row.value === "Sim" ? (
                                        <Badge color="success">Sim</Badge>
                                    ) : (
                                        <Badge color="danger">Não</Badge>
                                    ),
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "index",
                        desc: false,
                    },
                ]}
            />
        );
    }

    renderDocumentosTable(documentos) {
        if (documentos.length === 0) {
            return "Esta liberação não possui documentos.";
        }

        return (
            <ReactTable
                data={documentos}
                columns={[
                    {
                        Header: "Documentos",
                        columns: [
                            {
                                Header: "#",
                                accessor: "id",
                                maxWidth: 40,
                                Cell: row => <>{row.index + 1}</>,
                            },
                            {
                                Header: "Nome do Documento",
                                accessor: "url",
                                Cell: row => (
                                    <Badge
                                        href={`https://cdn-0.mpma.mp.br/evoto/${row.value}`}
                                        target="_blank"
                                        color="light"
                                    >
                                        {row.value}
                                    </Badge>
                                ),
                            },
                        ],
                    },
                ]}
                filterable
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum registro foi encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                className="-striped -highlight"
                pageSizeOptions={[15, 30, 50, 100]}
                defaultPageSize={15}
                defaultFilterMethod={filterTableMethod}
                defaultSorted={[
                    {
                        id: "index",
                        desc: false,
                    },
                ]}
            />
        );
    }

    render() {
        if (!this.props.liberacao) {
            return "";
        }

        return (
            <div>
                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    size="lg"
                >
                    <ModalHeader toggle={this.toggle}>
                        Detalhes da Liberação {this.props.liberacao.id}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6">
                                <strong>Data inicial:</strong>{" "}
                                <Moment format="DD/MM/YYYY HH:mm">
                                    {this.props.liberacao.data_inicial}
                                </Moment>
                            </div>
                            <div className="col-6">
                                <strong>Data final:</strong>{" "}
                                <Moment format="DD/MM/YYYY HH:mm">
                                    {this.props.liberacao.data_final}
                                </Moment>
                            </div>
                        </div>

                        <p>
                            <strong>Descrição: </strong>
                            {this.props.liberacao.descricao}
                        </p>

                        <hr />

                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === "1",
                                    })}
                                    onClick={() => {
                                        this.toggleTabs("1");
                                    }}
                                >
                                    Usuários
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === "2",
                                    })}
                                    onClick={() => {
                                        this.toggleTabs("2");
                                    }}
                                >
                                    Documentos
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        {this.renderUsuariosTable(
                                            this.props.liberacao.usuarios,
                                        )}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        {this.renderDocumentosTable(
                                            this.props.liberacao.documentos,
                                        )}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { liberacao: state.liberacao.liberacao };
}

export default connect(mapStateToProps, null)(ModalView);
