import React from 'react';
import * as actions from '../../../../actions';
import { Field, reduxForm } from 'redux-form';
import { compose } from "redux";
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import { Row, Col, Input } from 'reactstrap';
import { convertToSlug, maioPalavra46, minLength3, maxLength255, renderFieldGroupButton } from '../../../../utils/renderField';
import candidato_foto from '../../../../assets/img/camera.png';

class CandidatosForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            foto: '',
            selectedFile: null,
            msgBtn: 'Adicionar',
            setDisabled: true
        };

        this.handleNomeChange = this.handleNomeChange.bind(this);
        this.handleFotoChange = this.handleFotoChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidMount() { 
        this.handleClear()
    }

    componentDidUpdate() {
        this.props.getFormCandidatos({ nome: this.state.nome, foto: this.state.selectedFile })
    }

    handleNomeChange(event) {

        this.setState({
            nome: event.target.value
        },() => {
            this.enableButton();
        });
    }

    handleFotoChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let fileMaxSize = 1 * 6000 * 1000; // 6MB
        let extensoes = ['jpg','jpeg','png','gif'];

        if (!files.length)
            return;

        let file = files[0];
        
        let extensao = extensoes.filter(img => img === file.name.split('.').pop());
        
        if (extensao.length < 1) {
            toastr.error('Atenção', 'Extenções permitidas (jpg,jpeg,png e gif)');
            return;
        } else if (file.size > fileMaxSize) {
            toastr.error('Atenção', 'Tamanho máximo de 6mb ultrapassado');
            return;
        }

        this.createImage(files[0]);
        // Limpa o valor para poder adicionar a mesma imagem em sequencia
        e.target.value = null;
    }

    createImage(file) {
        let reader = new FileReader();

        reader.onload = (e) => {
            this.setState({
                selectedFile: file, foto: e.target.result
            },() => {
                this.enableButton();
            });
        };

        reader.readAsDataURL(file);
    }

    enableButton() {
        const {nome, foto } = this.state;
        
        const candidato = nome.split(' ');
        const maiorPalvra = candidato.filter(_ => _.length > 46);
        
        if(maiorPalvra.length === 0 && nome.length >= 3 && nome.length <= 255 && foto !== '') {
            this.setState({ setDisabled: false });
        } else {
            this.setState({ setDisabled: true });
        }
    }

    handleClear() {
        this.props.reset();
        this.props.getFormCandidatos({});
        this.setState({ setDisabled: true, msgBtn: 'Adicionar', nome: '', foto: '', selectedFile: null });
    }

    renameFilename(file) {
        const uid = (new Date().getTime()).toString(10);
        return convertToSlug(file.slice(0, -4))+"_"+ uid +"." + file.split('.').pop();
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.nome !== '' && this.state.selectedFile !== null && this.state.selectedFile.name !== null) {

            const imagem = this.renameFilename(this.state.selectedFile.name);
            //const imagem = this.state.selectedFile.name;

            const data = new FormData()
                data.append('arquivo_data[]', this.state.selectedFile, imagem)
            
                this.setState({
                    setDisabled: true,
                    msgBtn: 'Aguarde...',
                })

            this.props.sendFileCdn(data)
            .then(() => {
                this.props.onAddCandidato({ nome: this.state.nome, foto: imagem });
                
                this.handleClear();
            })
        } else {
            toastr.error('Atenção', 'Você deve informar o Nome e a Imagem do Candidato');
        }
    }

    render() {
        return(
            <div>
                <Row>
                    <Col xs="6" sm="2">
                        <label className="fotoCandidato">
                            {!this.state.foto && <img width="100" src={candidato_foto} alt="Clique para Alterar a Imagem" className="img-responsive img-thumbnail" />}
                            {this.state.foto && <img width="100" src={this.state.foto} alt="Clique para Alterar a Imagem" className="img-responsive img-thumbnail" />}
                            <Input
                                type="file"
                                size="50"
                                accept="image/png, image/jpeg, image/gif"
                                onChange={this.handleFotoChange}
                                name="foto" 
                                id="fotoCandidato" 
                            />
                        </label> 
                    </Col>
                    <Col xs="6" sm="10">
                        <Row>
                            <Col xs="6" sm="12">
                                <Field
                                    id="nome_candidato"
                                    icon="plus-circle"
                                    name="nome"
                                    type="text"
                                    onChange={this.handleNomeChange}
                                    label="Nome do candidato"
                                    component={renderFieldGroupButton}
                                    disabled={this.state.setDisabled}
                                    validate={[maioPalavra46, minLength3, maxLength255]}
                                    handleSubmit={this.handleSubmit}
                                    handleClear={this.handleClear}
                                    msgBtn={this.state.msgBtn}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default compose(
    connect(null, actions),
    reduxForm({ form: 'CandidatosForm' })
)(CandidatosForm);

