import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalCreateForm from './ModalCreateForm';

export default class ModalCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });

        this.props.toggleModal();
    }

    componentWillReceiveProps(props) {
        this.setState({ showModal: props.showModal })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.showModal} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>Adicionar liberação</ModalHeader>
                    <ModalBody>
                        <ModalCreateForm closeModal={this.toggle} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}