import React, { Component } from 'react'
import {browserName,deviceType} from 'react-device-detect';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../../actions';
import  CheckBox  from '../../../../utils/CheckBox'
import { toastr } from "react-redux-toastr";

class WizardModalVotar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            limiteSelecao: false,
            candidatos: []
        }

        this.toggle = this.toggle.bind(this)
        this.handleRadioChange = this.handleRadioChange.bind(this)
    }

    onSubmit = formProps => {
        
        formProps = this.state.candidatos;
        const qtdaSelecaoCandidato = this.props.qtdaSelecaoCandidato;

        let votos = [];

        formProps.forEach(candidato => {
            if (candidato.ischecked)
            votos.push({'candidatoId': candidato.id});
        })
        
        if (votos.length === 0) {
            toastr.error('Atenção', 'Selecione ao menos um candidato!');
        } else if (qtdaSelecaoCandidato > 0 && votos.length > qtdaSelecaoCandidato) {
            toastr.error('Atenção', `Limite de ${qtdaSelecaoCandidato} candidatos selecionados ultrapassado!`);
        } else {
            let serverData = {
                'ip': this.props.ipUsuario,
                'simulada': this.props.simulada,
                'votos': votos,
                'navegador': browserName + deviceType
            };
            
            this.props.votarEleicao(this.props.eleicaoId, serverData, (data) => {

                if(data.msg) {
                    toastr.success('Sucesso', data.msg);

                    //this.props.nextPage();
                } else {
                    toastr.warning('Erro', data.error);
                }
                    
            });
            
        }

    };

    componentDidMount() {

        this.setState({ isLoading: true });

        if (this.props.eleicaoId !== undefined) {

            if(this.props.simulada === 'true') {
                this.props.fetchEleicaoCandidatosSimulada(this.props.eleicaoId, (candidatos) => {
                    this.setState({ candidatos, isLoading: false });                
                });
            } else {
                this.props.fetchEleicaoCandidatosOriginal(this.props.eleicaoId, (candidatos) => {
                    this.setState({ candidatos, isLoading: false });                
                });
            }            
        }
    }

    handleAllChecked = (e) => {

        const candidatos = this.state.candidatos;

            candidatos.forEach(candidato => {
                if (candidato.nome !== 'VOTO EM BRANCO') {
                    candidato.ischecked = e.target.checked
                }
                if (candidato.nome === 'VOTO EM BRANCO') {
                    candidato.ischecked = false
                }
            })

        this.setState({ candidatos })
        
    }

    toggle() {

        const candidatos = this.state.candidatos;

        candidatos.forEach(candidato => {
            if (candidato.ischecked) {
                this.props.toggleModalVotarEleicao(!this.props.showModalVotarEleicao);

                setTimeout(() => {
                    this.props.fetchEleicoesLiberadas(() => {});
                }, 100);
            }
        })
    }

    handleCheckChieldElement = (e) => {

        const candidatos = this.state.candidatos;
        const qtdaSelecaoCandidato = this.props.qtdaSelecaoCandidato;

        candidatos.forEach(candidato => {
            if (candidato.id === parseInt(e.target.value, 10))
                candidato.ischecked = e.target.checked
            if (candidato.nome === 'VOTO EM BRANCO' && candidato.ischecked) {
                candidato.ischecked = e.target.checked
                candidatos.forEach(candidato => {
                    if (candidato.nome !== 'VOTO EM BRANCO')
                        candidato.ischecked = false
                })
            }

            const checkeds = candidatos.filter(candidato => candidato.ischecked === true)
            if(checkeds.length > qtdaSelecaoCandidato) candidato.ischecked = false
        })

        this.setState({ candidatos })
    }

    handleRadioChange = (e) => {

        const candidatos = this.state.candidatos;    
        
        candidatos.forEach(candidato => {
            if (candidato.id === parseInt(e.target.value, 10))
                candidato.ischecked = e.target.checked
            else
                candidato.ischecked = false
        })
        
        this.setState({ candidatos })
    }

    renderUnico(isLoading) {

        if (isLoading) {
            return ''
        }

        return (
            <div>
                {   
                    this.state.candidatos.map((candidato, index) => {   
                        return(
                            <div className="frb-group" key={index}>
                                <div className="frb frb-success">
                                    <input 
                                        type="radio"
                                        name="voto"
                                        id={candidato.id} 
                                        onChange={this.handleRadioChange} 
                                        value={candidato.id} 
                                    />
                                    <label htmlFor={candidato.id}>
                                        <span className="frb-title"><img width="100" src={`https://cdn-0.mpma.mp.br/evoto/${candidato.foto}`} alt="" className="img-responsive img-thumbnail" /></span>
                                        <span className="frb-description">{candidato.nome}</span>
                                    </label>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderVarios(isLoading, qtdaSelecaoCandidato) {

        if (isLoading) {
            return ''
        }

        return (
            <div>
                { qtdaSelecaoCandidato > 0 ? 
                <h6 className="text-center">Selecione até { qtdaSelecaoCandidato } candidatos</h6> : 
                <div><input type="checkbox" onChange={this.handleAllChecked} value="checkedall" /> Marcar Todos </div> }
                
                {
                    this.state.candidatos.map((candidato, index) => {
                        return(
                            (<CheckBox key={index} handleCheckChieldElement={this.handleCheckChieldElement}  {...candidato} />)
                        )
                    })
                }
            </div>
        )
    }

    renderPaginacao() {
        const {total, page} = this.props;

        let vtotal = (total > 1) ? total-1 : total;

        if(page === 0 && vtotal > 1) {
            return (
                <div className="text-right mt-3">
                    <button type="submit" className="next btn btn-info">Votar</button>
                </div>
            )
        } 
        else if(page === vtotal || (vtotal === 1 && page === 0)) {
            return (
                <div className="row mt-3">
                    <div className="col-12 text-right">
                        <button type="submit" onClick={this.toggle} className="btn btn-info">Votar</button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row mt-3">
                    <div className="col-12 text-right">
                        <button type="submit" className="next btn btn-info">Votar</button>
                    </div>
                </div>
            )
        }
        
    }

    render() {
        const { handleSubmit, eleicaoDesc, selecaoCandidato, qtdaSelecaoCandidato } = this.props;
        const { isLoading } = this.state;
        
        return (
            <div>
                <h4 className="text-center">{eleicaoDesc}</h4>
                <div className="col-xs-12 col-sm-12 col-md-12">
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        { (selecaoCandidato === 1) ? this.renderUnico(isLoading) : this.renderVarios(isLoading, qtdaSelecaoCandidato) }
                        { this.renderPaginacao() }
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { 
        showModalVotarEleicao: state.modal.showModalVotarEleicao,
        ipUsuario: state.auth.ipUsuario
    };
}

export default compose(
    connect(mapStateToProps, actions),
    reduxForm({ form: 'wizardeleicaoform' })
)(WizardModalVotar);

