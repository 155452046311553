import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';


import cdn from './cdn';
import auth from './auth';
import busca from './busca';
import modal from './modal';
import eleicao from './eleicao';
import liberacao from './liberacao';
import relatorio from './relatorio';
import formulario from './formulario';

export default combineReducers({
    cdn,
    auth,
    busca,
    modal,
    eleicao,
    liberacao,
    relatorio,
    formulario,
    form: formReducer,
    toastr: toastrReducer
});
