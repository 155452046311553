import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions";
import logo from "../../assets/img/e-Voto.svg";
import getUserIP from "../../utils/GetIP";

class GuestHeader extends Component {
    constructor(props) {
        super(props);

        this.logOutClickHandler = this.logOutClickHandler.bind(this);
    }

    componentDidMount() {
        getUserIP(ipUsuario => {
            this.props.setIPUsuario(ipUsuario);
        });
    }

    logOutClickHandler(event) {
        event.preventDefault();

        this.props.logout();
    }

    render() {
        return (
            <nav className="navbar navbar-dark bg-dark">
                <Link className="navbar-brand" to="/">
                    <img src={logo} className="e-voto-logo" alt="e-Voto" />
                </Link>

                {this.props.sair && (
                    <div
                        className="d-flex align-items-center justify-content-between"
                        hidden={!this.props.sair}
                    >
                        <span className="navbar-text p-2">
                            {this.props.loggedUser.nome.split(/[ ,]+/)[0]}
                        </span>
                        <button
                            className="btn btn-outline-danger"
                            onClick={this.logOutClickHandler}
                        >
                            Sair
                        </button>
                    </div>
                )}
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return { loggedUser: state.auth.loggedUser };
}

export default connect(mapStateToProps, actions)(GuestHeader);
